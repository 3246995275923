/* eslint-disable react-refresh/only-export-components */ // FIXME
import { useParams } from "react-router-dom"
import { Button } from "src/ui/Button/Button"
import styled, { css, useTheme } from "styled-components"
import { Icon } from "src/ui/Icon"
import { intlFormatDistance } from "date-fns"
import { AppLink } from "src/ui/AppLink/AppLink"
import { sortBy } from "lodash-es"
import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import {
  SplitterUtils_SplitterFragmentDocument,
  parseBatchIdentifiers,
} from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { RunHistoryValidationResultFragment, ValidationDocument } from "src/api/graphql/graphql-operations"
import { ApolloError, useQuery } from "@apollo/client"
import { formatLocalDateWithTime } from "src/common/utils/formatTime"
import { exists } from "src/common/utils/typeguards"
import { SidebarCard } from "src/ui/Card/Card"
import { Flex, Tooltip } from "antd"
import { theme } from "src/ui/themes/theme"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { makeFragmentData } from "src/api/graphql"
import { BodyStrong, DescriptionNormal } from "src/ui/typography/Text/Text"

const OL = styled.ol`
  all: unset;
`

const ListItemTextWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.horizontal.xs};
  `};
`

const ListItemTitle = styled(BodyStrong)`
  && {
    margin-bottom: 0;
    ${({ theme }) => css`
      color: ${theme.colors.neutralColorPalette.blacks.colorText};
    `}
  }
`
const ListItemLink = styled(AppLink)<{ selected?: boolean }>`
  ${({ theme, selected }) => css`
    display: block;
    padding: ${theme.spacing.vertical.xxxs} ${theme.spacing.horizontal.xs};

    ${selected &&
    css`
      background: ${theme.colors.primaryColors.gxAccentBackground};
    `};
    &:hover {
      background: ${theme.colors.primaryColors.gxAccentBackground};
    }

    &:focus {
      border: 1px solid ${theme.colors.primaryColors.gxPrimaryLight};
    }
  `};
`

function RefreshButton({ onClick }: { onClick: () => void }) {
  return <Button aria-label="Refresh Results" title="Refresh Results" onClick={onClick} icon="refresh" size="small" />
}

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function fmtApprox(iso: string) {
  return capitalize(intlFormatDistance(new Date(iso), new Date()))
}

export interface RunHistoryItem extends RunHistoryValidationResultFragment {
  toUrl: string
}

function RunHistoryListItem({ validationRun }: { validationRun: RunHistoryItem }) {
  const { validationResultId: _validationResultId } = useParams<{
    validationResultId: string
  }>()
  const validationResultId = decodeURIComponent(_validationResultId ?? "")

  const theme = useTheme()
  const successIcon = <Icon name="checkCircleSolid" color={theme.colors.success.gxSuccess} />
  const failIcon = <Icon name="exclamationCircleSolid" color={theme.colors.error.gxError} />
  const batchInfo = parseBatchIdentifiers(
    validationRun.batchIdentifiers,
    // using makeFragmentData is not ideal here, but it's going to take significantly more refactoring work
    // in order to use fragment masking in this component, so we're just gonna mask this data
    // so that parseBatchIdentifiers can use fragment masking
    validationRun.splitter ? makeFragmentData(validationRun.splitter, SplitterUtils_SplitterFragmentDocument) : null,
  )
  const isScheduledRun = !!validationRun.job?.scheduleId
  const isGxSchedulerEnabled = useIsFeatureEnabled("gxSchedulerEnabled")
  return (
    <li key={validationRun.runId}>
      <ListItemLink
        to={validationRun.toUrl}
        selected={validationRun.id === validationResultId}
        tabIndex={0}
        replace={true}
      >
        <Flex>
          {validationRun.success ? successIcon : failIcon}
          <ListItemTextWrapper>
            <ListItemTitle>{fmtApprox(validationRun.ranAt)}</ListItemTitle>
            <DescriptionNormal>{formatLocalDateWithTime(validationRun.ranAt)}</DescriptionNormal>
            <DescriptionNormal>
              {batchInfo && batchInfo.batch}
              {batchInfo && batchInfo.isMulticolumn && <SplitterTooltip title={batchInfo.restBatch || ""} />}
            </DescriptionNormal>
            {validationRun.checkpointName && (
              <DescriptionNormal>Checkpoint: {validationRun.checkpointName}</DescriptionNormal>
            )}
          </ListItemTextWrapper>
          {isGxSchedulerEnabled && isScheduledRun && (
            <Tooltip title="Run by GX Scheduler">
              <Icon size="16px" name="calendar" color={theme.colors.neutralColorPalette.blacks.colorTextTertiary} />
            </Tooltip>
          )}
        </Flex>
      </ListItemLink>
    </li>
  )
}

interface RunHistoryProps {
  validationRunHistories: (RunHistoryItem | null)[] | undefined
  loading: boolean
  error: ApolloError | undefined
  onRefetch?: () => void
  validationId: string | undefined
  expectationSuiteId: string
  assetId: string
  checkpointId?: string
}

function RunHistoryUI({
  validationRunHistories: _validationRunHistories,
  loading,
  error,
  onRefetch,
  expectationSuiteId,
  assetId,
}: RunHistoryProps) {
  let validationRunHistories = (_validationRunHistories ?? []).filter((run) => Boolean(run))
  validationRunHistories = sortBy(validationRunHistories, [(run) => -Date.parse(run?.ranAt as string)])
  const theme = useTheme()
  const showChartIcon = <Icon name="analysis" color={theme.colors.primaryColors.gxPrimaryDark} />
  const toUrl = "data-assets/" + assetId + "/validations/expectation-suites/" + expectationSuiteId + "/results"

  const useGetTitle = () => {
    let runHistoryLen = ""
    if (!loading) {
      runHistoryLen = ` (${validationRunHistories.length < 100 ? `${validationRunHistories.length}` : "99+"})`
    }
    return (
      <>
        <div>Batches & run history{runHistoryLen}</div>
      </>
    )
  }
  const { validationResultId: _validationResultId } = useParams<{ validationResultId: string }>()
  const selectedAllRuns = _validationResultId === undefined ? true : false

  return (
    <SidebarCard
      title={useGetTitle()}
      headStyle={{ paddingTop: theme.spacing.vertical.xs, paddingBottom: theme.spacing.vertical.xs }}
      bodyStyle={{ padding: `${loading ? "8px" : "0"}`, maxHeight: "300px", overflowY: "auto" }}
      extra={onRefetch && <RefreshButton onClick={() => onRefetch()} />}
      loading={loading}
    >
      {error && (
        <FeedbackMessageDoNotUse
          title="Unable to view Run History"
          description="There was an error getting the Run History for this Validation."
          type="error"
        />
      )}
      <OL>
        <ListItemLink to={toUrl} selected={selectedAllRuns} tabIndex={0}>
          <Flex>
            {showChartIcon}
            <ListItemTextWrapper>
              <ListItemTitle>All Runs</ListItemTitle>
              <DescriptionNormal>Validation Results over time</DescriptionNormal>
            </ListItemTextWrapper>
          </Flex>
        </ListItemLink>
        {validationRunHistories
          .filter((valRun): valRun is RunHistoryItem => !!valRun)
          .map((valRun) => (
            <RunHistoryListItem validationRun={valRun} key={valRun.id} />
          ))}
      </OL>
    </SidebarCard>
  )
}

function RunHistoryContainer() {
  const {
    checkpointId: _checkpointId,
    validationId: _validationId,
    expectationSuiteId: _expectationSuiteId,
    assetId: _assetId,
  } = useParams<{
    checkpointId: string
    validationId: string
    expectationSuiteId: string
    assetId: string
  }>()

  if (_checkpointId === undefined) {
    throw new Error("Missing checkpoint id")
  }
  if (_validationId === undefined) {
    throw new Error("Missing validation id")
  }

  const checkpointId = decodeURIComponent(_checkpointId)
  const validationId = decodeURIComponent(_validationId)
  const isGxSchedulerEnabled = useIsFeatureEnabled("gxSchedulerEnabled")
  const { data, loading, error, refetch } = useQuery(ValidationDocument, {
    variables: {
      id: validationId,
      includeJobs: isGxSchedulerEnabled,
    },
  })
  let assetId = _assetId
  let expectationSuiteId = _expectationSuiteId
  if (expectationSuiteId === undefined || assetId === undefined) {
    assetId = data?.validation?.assetRefId || ""
    expectationSuiteId = data?.validation?.expectationSuiteV2?.id || ""
  }
  const runHistories = (data?.validation?.runHistory ?? []).filter(exists).map((run) => {
    return {
      ...run,
      toUrl: `/checkpoints/${encodeURIComponent(checkpointId)}/validations/${encodeURIComponent(
        validationId,
      )}/results/${encodeURIComponent(run.id || "")}`,
    }
  })

  return (
    <RunHistoryUI
      validationRunHistories={runHistories}
      loading={loading}
      error={error}
      onRefetch={refetch}
      validationId={validationId}
      expectationSuiteId={expectationSuiteId}
      assetId={assetId}
      checkpointId={checkpointId}
    />
  )
}

export { RunHistoryContainer as RunHistory, RunHistoryUI }

function SplitterTooltip({ title }: { title: string }) {
  return (
    <Tooltip title={title}>
      <Icon
        style={{ marginTop: "2px" }}
        name="questionCircle"
        size="18px"
        color={theme.colors.neutralColorPalette.blacks.colorTextQuaternary}
      />
    </Tooltip>
  )
}
