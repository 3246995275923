import { Maybe } from "graphql/jsutils/Maybe"
import { GroupName } from "src/Expectation/words"
import { InteractiveExpectationFragment } from "src/api/graphql/graphql-operations"

type DomainKwargs = { column?: string; column_A?: string; column_B?: string; column_list?: string[] }

function getDomainKwargs(expectationConfig?: Maybe<InteractiveExpectationFragment>): DomainKwargs {
  if (typeof expectationConfig?.domain?.domainKwargs !== "string") {
    throw new Error(
      "type error: unable to parse domainKwargs. expected string, got " +
        typeof expectationConfig?.domain?.domainKwargs,
    )
  }
  try {
    return JSON.parse(expectationConfig?.domain?.domainKwargs)
  } catch (error: unknown) {
    console.error(error)
    return {}
  }
}

function getMulticolumnGroupName(columnList: string[]): string {
  return columnList.sort().join(", ")
}

export function getExpectationGroupName(expectationConfig?: Maybe<InteractiveExpectationFragment>): GroupName | string {
  const domainType = expectationConfig?.domain?.domainType
  if (domainType === "TABLE") {
    const expectationType = expectationConfig?.expectationType
    if (expectationType === "unexpected_rows_expectation") return GroupName.SQL

    return GroupName.TABLE
  }

  if (domainType === "COLUMN") {
    const domainKwargs = getDomainKwargs(expectationConfig)
    if (!domainKwargs.column) {
      console.error(`no column name found in domain kwargs for expectation: ${expectationConfig?.expectationType}`)
    } else {
      return domainKwargs.column
    }
  }

  if (domainType === "COLUMN_PAIR") {
    const domainKwargs = getDomainKwargs(expectationConfig)
    if (!domainKwargs.column_A) {
      console.error(`no column_A name found in domain kwargs for expectation: ${expectationConfig?.expectationType}`)
    }
    if (!domainKwargs.column_B) {
      console.error(`no column_B name found in domain kwargs for expectation: ${expectationConfig?.expectationType}`)
    }
    if (domainKwargs.column_A && domainKwargs.column_B) {
      return getMulticolumnGroupName([domainKwargs.column_A, domainKwargs.column_B])
    }
  }

  if (domainType === "MULTICOLUMN") {
    const domainKwargs = getDomainKwargs(expectationConfig)
    if (!domainKwargs.column_list) {
      console.error(`no column_list found in domain kwargs for expectation: ${expectationConfig?.expectationType}`)
    } else {
      return getMulticolumnGroupName(domainKwargs.column_list)
    }
  }

  if (!domainType) {
    const kwargs = JSON.parse(expectationConfig?.kwargs ?? "{}")
    if ("windows" in kwargs && kwargs.windows.length > 0) {
      return GroupName.WINDOWED
    }
  }

  return GroupName.UNKNOWN
}
