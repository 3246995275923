import { message } from "antd"
import { useMutation, useQuery } from "@apollo/client"
import { CreateExpectationDocument, ExpectationSuiteDocument } from "src/api/graphql/graphql-operations"
import { CREATE_EXPECTATION_SUCCESS } from "src/Expectation/CreateExpectationDrawer/words"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { useAnalytics } from "src/analytics/useAnalytics"
import {
  DrawerPage,
  emptyExpectation,
  useCreateExpectationDrawerContext,
} from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import { CreateExpectationDrawer } from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawer"
import { ExpectationSuiteSelectorForDrawer } from "src/Expectation/CreateExpectationDrawer/ExpectationSuiteSelectorForDrawer"
import { useCallback } from "react"

interface CreateExpectationForSuiteDrawerProps {
  expectationSuiteId: string
}

function CreateExpectationForSuiteDrawer(props: CreateExpectationForSuiteDrawerProps) {
  const {
    onClose,
    setPageNumber,
    setSuiteId,
    setSuiteName,
    setSelectedExpectation,
    setJsonValue,
    form,
    config,
    setScheduleIdAsUnpaused,
  } = useCreateExpectationDrawerContext()

  const posthog = useAnalytics()

  setSuiteId(props.expectationSuiteId)

  const expectationSuite = useQuery(ExpectationSuiteDocument, {
    variables: { id: props.expectationSuiteId },
    onCompleted: (data) => {
      setSuiteName(data.expectationSuiteV2?.name ?? null)
    },
  })

  // Use this mutation when creating an Expectation for an existing Suite
  const [createExpectationMutation, createExpectationMutationResult] = useMutation(CreateExpectationDocument, {
    variables: {
      input: {
        expectationSuiteId: props.expectationSuiteId,
        config: config,
      },
    },
    refetchQueries: [{ query: ExpectationSuiteDocument, variables: { id: props.expectationSuiteId } }],
    onError: () => {
      posthog?.capture("expectation.create_failed")
    },
  })

  const handlePostCreation = useCallback(
    (addMore?: "addMore") => {
      message.success(CREATE_EXPECTATION_SUCCESS, MESSAGE_DURATION_SECONDS)
      posthog?.capture("expectation.create_succeeded")
      const checkpointId = expectationSuite.data?.expectationSuiteV2?.validations[0]?.checkpoints[0]?.id
      // if the Expectation is the first one we are adding, then the existing Suite has a length of 0
      const isFirstExpectation = (expectationSuite.data?.expectationSuiteV2?.expectations?.length ?? 0) === 0
      setScheduleIdAsUnpaused(checkpointId, isFirstExpectation)

      if (addMore) {
        setPageNumber(DrawerPage.ExpectationPicker)
        setSelectedExpectation(emptyExpectation)
        setJsonValue("")
      } else {
        onClose()
      }
    },
    [
      expectationSuite.data?.expectationSuiteV2?.expectations?.length,
      expectationSuite.data?.expectationSuiteV2?.validations,
      onClose,
      posthog,
      setJsonValue,
      setPageNumber,
      setScheduleIdAsUnpaused,
      setSelectedExpectation,
    ],
  )

  const onCloseAndResetMutation = () => {
    if (createExpectationMutationResult.error?.message) {
      createExpectationMutationResult.reset()
    }
    setPageNumber(DrawerPage.ExpectationPicker)
    onClose()
  }

  const onSave = async (addMore?: "addMore") => {
    try {
      await form.validateFields()
      await createExpectationMutation()
      handlePostCreation(addMore)
    } catch (error) {
      console.error(error)
    }
  }

  const expectationSuiteSelectorForDrawer = (
    <ExpectationSuiteSelectorForDrawer
      initialSuiteName={expectationSuite.data?.expectationSuiteV2?.name ?? ""}
      allowChangeInitialSuiteName={false}
    />
  )

  return (
    <CreateExpectationDrawer
      onClose={onCloseAndResetMutation}
      onSave={onSave}
      expectationSuiteSelectorForDrawer={expectationSuiteSelectorForDrawer}
      loading={createExpectationMutationResult.loading}
      error={createExpectationMutationResult.error?.message}
      variant="For Suite"
    />
  )
}

export { CreateExpectationForSuiteDrawer }
