import { Card, Card as AntdCard, CardProps, ConfigProvider, Flex, Space, Tag } from "antd"
import styled, { useTheme } from "styled-components"
import Meta, { CardMetaProps } from "antd/es/card/Meta"
import * as React from "react"
import { Heading3 } from "src/ui/typography/Text/Text"

function SidebarCard({ title, ...props }: CardProps) {
  const theme = useTheme()
  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            colorBorderSecondary: theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder,
            headerBg: theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgSecondary,
          },
        },
      }}
    >
      <AntdCard size="small" title={<Heading3>{title}</Heading3>} {...props} />
    </ConfigProvider>
  )
}

const StyledAntdCard = styled(AntdCard)`
  border: 1px solid ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
  p {
    margin: 0;
  }
`

function NoteCard(props: CardProps) {
  const theme = useTheme()

  return (
    <StyledAntdCard
      size="small"
      headStyle={{
        backgroundColor: theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgSecondary,
        fontWeight: 500,
        borderColor: theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder,
      }}
      title={props.title || "Notes"}
      {...props}
    />
  )
}

const ExpectationInfoStyledCard = styled(StyledAntdCard)`
  background-color: ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgPrimary};
  font-weight: 500;
  border-color: ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
`

export type ExpectationInfoCardProps = CardProps & {
  category?: string
}

function ExpectationInfoCard(props: ExpectationInfoCardProps) {
  const theme = useTheme()

  const extra = props.category ? <Tag>{props.category}</Tag> : undefined

  return (
    <ExpectationInfoStyledCard
      size="small"
      extra={extra}
      style={{ marginBottom: theme.spacing.vertical.m }}
      {...props}
    />
  )
}

interface AlertCardProps {
  metaProps: CardMetaProps
  actions: React.ReactNode
  description: React.ReactNode
}

function AlertCard(props: AlertCardProps) {
  return (
    <Card bodyStyle={{ padding: "16px" }}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Flex justify="space-between">
          <Meta {...props.metaProps} />
          {props.actions}
        </Flex>
        {props.description}
        <Space />
      </Space>
    </Card>
  )
}

export { ExpectationInfoCard, NoteCard, SidebarCard, AlertCard }
