import { useState } from "react"
import { useMutation } from "@apollo/client"
import { Flex, Switch, Tooltip, Form } from "antd"
import { getHourlyIntervalFromCron, getNextRunFromCron } from "src/common/utils/cron"
import { formatLocalCalendarDateWithTimeAndTimeZone } from "src/common/utils/formatTime"
import { graphql } from "src/api/graphql/gql"
import { unmaskFragment, FragmentType } from "src/api/graphql/fragment-masking"
import { Icon } from "src/ui/Icon"
import { theme } from "src/ui/themes/theme"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { EditSchedulePopover } from "src/DataAssets/AssetDetails/Expectations/EditSchedulePopover"
import { BodyNormal } from "src/ui/typography/Text/Text"

export const ScheduleSummaryFragmentDocument = graphql(`
  fragment ScheduleSummary on Schedule {
    id
    isEnabled
    startTime
    schedule
    ...EditScheduleForm_Schedule
  }
`)

export const ToggleScheduleMutationDocument = graphql(`
  mutation ToggleSchedule($id: UUID!) {
    toggleSchedule(input: { id: $id }) {
      id
      isEnabled
      schedule
      startTime
    }
  }
`)

export function SimpleScheduleSummary({
  schedule: maskedSchedule,
  hasExpectations,
}: {
  schedule: FragmentType<typeof ScheduleSummaryFragmentDocument> | undefined | null
  hasExpectations: boolean
}) {
  const schedule = unmaskFragment(ScheduleSummaryFragmentDocument, maskedSchedule)
  const [errorText, setErrorText] = useState<string | undefined>()
  const [toggleSchedule, { loading: toggleScheduleLoading }] = useMutation(ToggleScheduleMutationDocument, {
    onError: (e) => {
      setErrorText(e.message)
    },
  })

  const isEditorRole = useRequireRole("EDITOR")

  const nextRunDateTime = schedule
    ? formatLocalCalendarDateWithTimeAndTimeZone(getNextRunFromCron(schedule?.schedule).toISOString())
    : ""
  const hourlyInterval = schedule ? getHourlyIntervalFromCron(schedule?.schedule) : ""
  const intervalDisplayString = `Every ${hourlyInterval} hour${hourlyInterval !== 1 ? "s" : ""}`

  return (
    <Form.Item {...(errorText && { validateStatus: "error", help: errorText })}>
      <Flex gap={theme.spacing.horizontal.xxs} align="center">
        <Tooltip title="Validation schedule">
          <Icon small name="calendar" disabled={!schedule?.isEnabled} />
        </Tooltip>
        {isEditorRole && (
          <Tooltip title={!hasExpectations ? "Add an Expectation to activate the schedule." : null}>
            <Switch
              checked={schedule?.isEnabled}
              checkedChildren="Active"
              unCheckedChildren="Paused"
              onChange={() => schedule?.id && toggleSchedule({ variables: { id: schedule.id } })}
              loading={!schedule?.id || toggleScheduleLoading}
              aria-label="Schedule On/Off Toggle"
            />
          </Tooltip>
        )}
        {schedule?.isEnabled ? (
          <BodyNormal>
            Next run {nextRunDateTime} | {intervalDisplayString}
          </BodyNormal>
        ) : (
          <BodyNormal $color="gxPrimaryLight">{intervalDisplayString}</BodyNormal>
        )}
        {isEditorRole && <EditSchedulePopover schedule={schedule} />}
      </Flex>
    </Form.Item>
  )
}
