import { Space } from "antd"
import { AppModal, AppModalProps } from "src/ui/AppModal/AppModal"
import { Heading2 } from "src/ui/typography/Text/Text"
import { Icon } from "src/ui/Icon/Icon"
import { useTheme } from "styled-components"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { DELETE_MODAL_WIDTH } from "src/common/config"

type DeleteResourceModalProps = AppModalProps & {
  // Unique name of the resource to be deleted (ex: "Sample Data Asset").  Will show in the message text.
  name: string
  // The type of resource being deleted (ex: "Data Asset"). Will show in the modal title and message text.
  resourceTitle: string
  // Optional: Display an alternate string for the resource title in the message text. Will default to the value of `resourceTitle` if not provided.
  alternateResourceTitle?: string
  errorMessage?: string
}

export const DeleteResourceModal = ({
  name,
  resourceTitle,
  alternateResourceTitle,
  errorMessage,
  ...props
}: DeleteResourceModalProps) => {
  const theme = useTheme()
  return (
    <AppModal
      {...props}
      width={DELETE_MODAL_WIDTH}
      okButtonProps={{ ...props.okButtonProps, danger: true }}
      okText="Delete"
      title={
        <Space direction="horizontal" align="center">
          <Icon size="28px" name="trash" lineHeight="2px" color={theme.colors.error.gxError} />
          <Heading2>Delete {resourceTitle}</Heading2>
        </Space>
      }
    >
      <>
        This {alternateResourceTitle ?? resourceTitle} will be permanently deleted and cannot be restored. Are you sure
        you want to delete <b>{name}</b>?
      </>
      {errorMessage && <AlertBanner message={`Failed to delete ${resourceTitle}`} description={errorMessage} />}
    </AppModal>
  )
}
