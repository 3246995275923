import { useState } from "react"
import { useQuery } from "@apollo/client"
import { Flex, Table, Tag, Tooltip } from "antd"
import type { TableProps } from "antd"
import { useParams } from "react-router-dom"
import { uniqBy } from "lodash-es"
import styled from "styled-components"

import { graphql } from "src/api/graphql/gql"
import { PageHeader } from "src/ui/PageHeader/PageHeader"
import { FragmentType } from "src/api/graphql/fragment-masking"
import {
  ScheduleSummaryFragmentDocument,
  SimpleScheduleSummary,
} from "src/DataAssets/AssetDetails/Expectations/SimpleScheduleSummary"
import { DataAssetTabs } from "src/DataAssets/AssetDetails/DataAssetTabs"
import { MainContent } from "src/layout/MainContent"
import { getDataAssetBreadcrumbs } from "src/DataAssets/AssetDetails/DataAssetBreadcrumbs"
import { ExpectationsTab_GetExpectationsQuery, ExpectationValidationResultV2, Domain } from "src/api/graphql/graphql"
import { theme } from "src/ui/themes/theme"
import { Button } from "src/ui/Button/Button"
import {
  SimpleEditExpectationDrawer,
  EditExpectationFragment,
} from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationDrawer/SimpleEditExpectationDrawer"
import { SimpleNewExpectationDrawer } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationDrawer/SimpleNewExpectationDrawer"
import { assertNever } from "src/DataAssets/utils"
import { getRenderer } from "src/Expectation/utils"
import {
  BatchDefinitionDescription_SplitterFragmentDocument,
  BatchDefinitionWithEditing,
} from "src/DataAssets/AssetDetails/Splitters/BatchDefinitionWithEditing"
import { DeleteExpectationModal } from "src/DataAssets/AssetDetails/Expectations/DeleteExpectationModal"

const SeeAllButton = styled(Button)`
  display: inline-flex;
`

export const ExpectationsTabDataAssetDocument = graphql(`
  query ExpectationsTabDataAsset($id: UUID!) {
    dataAsset(id: $id) {
      name
      schedule {
        ...ScheduleSummary
      }
      splitter {
        ...BatchDefinitionDescription_Splitter
      }
      datasourceV2 {
        ...DataAssetBreadcrumbs_DataSource
        name
        type
        config
      }
    }
  }
`)

export const ExpectationsTab_GetExpectationsDocument = graphql(`
  query ExpectationsTab_GetExpectations($input: GetExpectationsInput!) {
    getExpectations(input: $input) {
      ...EditExpectationDrawer_Expectation
      domain {
        domainKwargs
        domainType
        id
        columns {
          name
          order
        }
      }
      geCloudId
      kwargs
      expectationType
      renderedContent {
        ...RenderedExpectation
      }
      validationResults(input: { limit: 1 }) {
        runTime
        success
      }
    }
  }
`)

type OnChange = TableProps<DataType>["onChange"]
type SortOrder = "descend" | "ascend" | null

export const SimpleExpectationsTab = () => {
  const { assetId } = useParams<{
    assetId: string
  }>()

  const [editingExpectationFragment, setEditingExpectationFragment] = useState<EditExpectationFragment | null>(null)

  const { data: dataAssetData, loading: dataAssetLoading } = useQuery(ExpectationsTabDataAssetDocument, {
    variables: {
      id: assetId ?? "", // would never pass empty string, but compilers must be satisfied
    },
    skip: !assetId,
  })

  const { data: expectationsData, loading: expectationsLoading } = useQuery(ExpectationsTab_GetExpectationsDocument, {
    variables: {
      input: { dataAssetId: assetId ?? "" }, // would never pass empty string, but compilers must be satisfied
    },
    skip: !assetId,
  })

  const [domainSortOrder, setDomainSortOrder] = useState<SortOrder>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange: OnChange = (pagination, filters, sorter) => {
    //console.log("Various parameters", pagination, filters, sorter)
    switch (domainSortOrder) {
      case null:
        setDomainSortOrder("ascend")
        break
      case "ascend":
        setDomainSortOrder("descend")
        break
      case "descend":
      default:
        setDomainSortOrder(null)
        break
    }
  }

  const dataAssetName = dataAssetData?.dataAsset?.name ?? ""

  return (
    <PageHeader
      headerContent={{
        title: dataAssetName,
        rootPath: "data-assets",
        navigateBackTo: "/data-assets",
        customBreadcrumbs: getDataAssetBreadcrumbs({
          dataSource: dataAssetData?.dataAsset?.datasourceV2,
          tooltipPlacement: "right",
        }),
        footer: <DataAssetTabs currentTab="expectations" />,
      }}
      loading={expectationsLoading || dataAssetLoading}
    >
      <MainContent $noIllustration>
        <Table
          title={(tableData) => (
            <ExpectationsTableHeader
              dataAssetId={assetId}
              schedule={dataAssetData?.dataAsset?.schedule}
              hasExpectations={tableData.length > 0}
              splitterData={dataAssetData?.dataAsset?.splitter}
            />
          )}
          id="simple-expectations-table"
          columns={getColumns(expectationsData, domainSortOrder, assetId ?? "", setEditingExpectationFragment)}
          dataSource={getExpectationRowData(expectationsData, domainSortOrder)}
          loading={expectationsLoading}
          onChange={handleChange}
          pagination={{ hideOnSinglePage: true, position: ["bottomRight"], pageSize: 15 }}
          rowKey={({ domain }) => domain.id}
        />
        {assetId && editingExpectationFragment && (
          <SimpleEditExpectationDrawer
            dataAssetId={assetId}
            expectation={editingExpectationFragment}
            open={!!editingExpectationFragment}
            close={() => setEditingExpectationFragment(null)}
          />
        )}
      </MainContent>
    </PageHeader>
  )
}

function ExpectationsTableHeader({
  dataAssetId,
  schedule,
  hasExpectations,
  splitterData,
}: {
  dataAssetId: string | undefined
  schedule: FragmentType<typeof ScheduleSummaryFragmentDocument> | null | undefined
  hasExpectations: boolean
  splitterData: FragmentType<typeof BatchDefinitionDescription_SplitterFragmentDocument> | null | undefined
}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Flex justify="space-between">
        <SimpleScheduleSummary schedule={schedule} hasExpectations={hasExpectations} />
        <Button icon="plus" size="large" type="primary" onClick={() => setIsOpen(true)}>
          New Expectation
        </Button>
        {dataAssetId && (
          <SimpleNewExpectationDrawer dataAssetId={dataAssetId} open={isOpen} close={() => setIsOpen(false)} />
        )}
      </Flex>
      {splitterData && (
        <BatchDefinitionWithEditing dataAssetId={dataAssetId ?? ""} splitterDataFragment={splitterData} />
      )}
    </>
  )
}

type DomainData = {
  id: string
  name: string
  sortValue: string
  truncateInfo: { truncate: boolean; fullName: string; restColumns?: string }
}

interface DataType {
  domain: DomainData
  expectation: string | JSX.Element | null
  lastRun: Omit<ExpectationValidationResultV2, "__typename">
  actions: { expectationFragment: EditExpectationFragment; expectationId: string | null }
}

const getColumns = (
  data: ExpectationsTab_GetExpectationsQuery | undefined,
  domainSortOrder: SortOrder,
  assetId: string,
  onEdit: (expectationFragment: EditExpectationFragment) => void,
): TableProps<DataType>["columns"] => [
  {
    title: "Domain",
    dataIndex: "domain",
    key: "domain",
    filters: getDomainFilter(data),
    onFilter: (value, record) => record.domain.name === value,
    filterMultiple: true,
    sorter: (a, b) => sorter(a.domain.sortValue, b.domain.sortValue),
    sortOrder: domainSortOrder,
    render: (_, { domain }) => <DomainCol {...domain} />,
    width: "20%",
  },
  {
    title: "Expectation",
    dataIndex: "expectation",
    key: "exp",
    width: "55%",
  },
  {
    title: "Last run",
    dataIndex: "lastRun",
    key: "run",
    render: (_, { lastRun }) => {
      const color = lastRun.success ? theme.colors.success.gxSuccess : theme.colors.error.gxError
      return (
        <span>
          <Tag color={color}>{lastRun.success ? "Pass" : "Fail"}</Tag> {lastRun.runTime}
        </span>
      )
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (_, { actions }) => {
      const { expectationId, expectationFragment } = actions
      return (
        <>
          <Button
            type="text"
            aria-label="Edit Expectation"
            icon="edit"
            disabled={!expectationFragment}
            onClick={() => (expectationFragment ? onEdit(expectationFragment) : undefined)}
          />
          <DeleteExpectationModal expectationId={expectationId} assetId={assetId} />
        </>
      )
    },
  },
]

function DomainCol(domain: DomainData) {
  const { name, truncateInfo } = domain
  const [isHidden, setIsHidden] = useState(true)

  return (
    <span>
      {!truncateInfo.truncate && name}
      {truncateInfo.truncate && isHidden && (
        <>
          {`${name}... `}
          <SeeAllButton size="small" type="text" onClick={() => setIsHidden(false)}>
            See all
          </SeeAllButton>
        </>
      )}
      {truncateInfo.truncate && !isHidden && <span>{truncateInfo.fullName}</span>}
    </span>
  )
}

const sorter = (a: string, b: string) => a.localeCompare(b, undefined, { numeric: true })
const noDomainPlaceholder = { name: "", sortValue: "", truncateInfo: { truncate: false, fullName: "" } }

const getExpectationRowData = (
  data: ExpectationsTab_GetExpectationsQuery | undefined,
  domainSortOrder: SortOrder,
): DataType[] => {
  if (!data?.getExpectations) {
    return []
  }

  return data?.getExpectations?.map((exp) => {
    const { name, sortValue, truncateInfo } = exp?.domain
      ? getDomainColumn(exp.domain, domainSortOrder)
      : noDomainPlaceholder

    return {
      domain: { name, sortValue, truncateInfo, id: exp?.domain?.id ?? "" },
      expectation:
        getRenderer({ renderedValue: exp?.renderedContent?.[0], fallback: exp?.expectationType ?? undefined }) ?? null,
      lastRun: {
        success: exp?.validationResults?.[0]?.success ?? undefined,
        runTime: exp?.validationResults?.[0]?.runTime ?? "Unknown",
      },
      actions: { expectationId: exp?.geCloudId ?? null, expectationFragment: exp },
    }
  })
}

const getDomainColumn = (domain: Domain, domainSortOrder: SortOrder): Omit<DomainData, "id"> => {
  const MAXCOLNR = 4
  const getSingleColSortValue = (name?: string | null) => (!name || !domainSortOrder ? "" : name)
  const getMultiColSortValue = (columns?: Domain["columns"]) => {
    if (!columns || !domainSortOrder) {
      return ""
    }

    if (columns.length === 1) {
      return columns[0].name ?? ""
    }

    const sortingArray = [...columns]
    const ascSortedColumns = sortingArray.sort((a, b) => {
      return sorter(a.name ?? "", b.name ?? "")
    })

    const lastElement = ascSortedColumns[ascSortedColumns.length - 1]
    return domainSortOrder === "ascend" ? (ascSortedColumns[0].name ?? "") : (lastElement.name ?? "")
  }

  switch (domain.domainType) {
    case "COLUMN": {
      const name = domain?.columns?.[0]?.name ?? ""
      return {
        name,
        sortValue: getSingleColSortValue(domain?.columns?.[0]?.name),
        truncateInfo: { truncate: false, fullName: name },
      }
    }
    case "COLUMN_PAIR":
    case "MULTICOLUMN": {
      const shouldTruncate = (domain.columns?.length ?? 0) > MAXCOLNR
      const columnsCopy = domain.columns ? [...domain.columns] : []
      const showCols = shouldTruncate ? columnsCopy.splice(0, MAXCOLNR) : domain.columns
      const joinColNames = (cols: Domain["columns"] | undefined) => (cols ? cols.map((col) => col.name).join(", ") : "")

      return {
        name: joinColNames(showCols ?? []),
        sortValue: getMultiColSortValue(domain.columns),
        truncateInfo: {
          truncate: shouldTruncate,
          restColumns: joinColNames(columnsCopy),
          fullName: joinColNames(domain.columns),
        },
      }
    }
    case "TABLE": {
      const name = "Table"
      return { name, sortValue: name, truncateInfo: { truncate: false, fullName: name } }
    }
    default: {
      const name = domain?.columns?.[0]?.name ?? ""
      return {
        name: domain.domainType ? assertNever(domain.domainType) : "",
        sortValue: getSingleColSortValue(name),
        truncateInfo: { truncate: false, fullName: name },
      }
    }
  }
}

const getDomainFilter = (data: ExpectationsTab_GetExpectationsQuery | undefined) => {
  if (!data?.getExpectations) {
    return []
  }

  const domainCol = data?.getExpectations?.map((exp) => {
    return exp?.domain ? getDomainColumn(exp?.domain, "ascend") : noDomainPlaceholder
  })

  const sortedFilterList = domainCol.sort((a, b) => sorter(a.sortValue, b.sortValue))
  const uniqSortedFilterList = uniqBy(sortedFilterList, "truncateInfo.fullName")
  return uniqSortedFilterList.map(({ name, truncateInfo }) => ({
    text: <DomainFilterItem name={name} truncateInfo={truncateInfo} />,
    value: name,
  }))
}

function DomainFilterItem({ name, truncateInfo }: Omit<DomainData, "id" | "sortValue">) {
  return truncateInfo.truncate ? (
    <Tooltip placement="topRight" title={`...${truncateInfo.restColumns}`}>
      <span>{`${name}...`}</span>
    </Tooltip>
  ) : (
    <span>{name}</span>
  )
}
