import { Flex } from "antd"
import { Param } from "src/ui/Param/Param"
import { pluralize } from "src/common/utils/strings"
import { getUIBatchDef } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { FragmentType, graphql, unmaskFragment } from "src/api/graphql"
import styled from "styled-components"
import { theme } from "src/ui/themes/theme"
import { EditBatchPopover } from "src/DataAssets/AssetDetails/Expectations/EditBatchPopover"
import { BodyNormal } from "src/ui/typography/Text/Text"

const Content = styled(BodyNormal)`
  margin-right: ${theme.spacing.horizontal.xxs};
`

type BatchDefinitionProps = {
  dataAssetId: string
  splitterDataFragment: FragmentType<typeof BatchDefinitionDescription_SplitterFragmentDocument>
}

export const BatchDefinitionDescription_SplitterFragmentDocument = graphql(`
  fragment BatchDefinitionDescription_Splitter on SplitterUnion {
    ... on SplitterYearAndMonthAndDay {
      columnName
    }
    ... on SplitterYearAndMonth {
      columnName
    }
    ... on SplitterYear {
      columnName
    }
    ... on SplitterColumnValue {
      columnName
    }
    ... on SplitterConvertedDateTime {
      columnName
    }
    ... on SplitterDatetimePart {
      columnName
    }
    ... on SplitterDividedInteger {
      columnName
    }
    ... on SplitterHashedColumn {
      columnName
    }
    ... on SplitterModInteger {
      columnName
    }
    ... on SplitterMultiColumnValue {
      columnNames
    }
  }
`)

export const BatchDefinitionWithEditing: React.FC<BatchDefinitionProps> = ({ dataAssetId, splitterDataFragment }) => {
  const { splitter, splitterColumns } = getSplitterData(splitterDataFragment)

  if (!splitter) {
    return null
  }

  const column = pluralize("column", splitterColumns?.split(",").length || 1)
  const batchColumnLabel = `Batch ${column}`

  return (
    <Flex gap={theme.spacing.horizontal.xxs} align="center">
      <BodyNormal $color="gxPrimaryLight">{batchColumnLabel}:</BodyNormal>
      <BodyNormal>
        <Param>{splitterColumns}</Param>
      </BodyNormal>
      <BodyNormal $color="gxPrimaryLight">Batch interval:</BodyNormal>
      <Content> {splitter}</Content>
      <EditBatchPopover dataAssetId={dataAssetId} splitter={splitter} />
    </Flex>
  )
}

export function getSplitterData(
  splitterData: FragmentType<typeof BatchDefinitionDescription_SplitterFragmentDocument>,
) {
  const splitterUnmasked = unmaskFragment(BatchDefinitionDescription_SplitterFragmentDocument, splitterData)
  const splitterType = splitterUnmasked?.["__typename"]
  const splitter = getUIBatchDef(splitterType)
  const isMulticolumnSplitter = splitterUnmasked?.["__typename"] === "SplitterMultiColumnValue"
  const splitterColumns = isMulticolumnSplitter
    ? splitterUnmasked?.columnNames.join(", ")
    : splitterUnmasked?.columnName

  return {
    splitter,
    splitterColumns,
  }
}
