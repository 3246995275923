import { ColumnLayoutProps, UISchema } from "@great-expectations/jsonforms-antd-renderers"
import {
  JsonFormsUISchemaRegistryEntry,
  UISchemaElement,
  LabelProps,
  RendererProps,
  JsonFormsRendererRegistryEntry,
} from "@jsonforms/core"
import { withJsonFormsLabelProps } from "@jsonforms/react"
import { AlertProps } from "antd"
import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"

export const WindowedFixedValuesUISchemaRegistry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath) => {
    if (schemaPath === "#/properties/to_be_between" && schema.properties && "min_value" in schema.properties) {
      return 100
    }
    return -1
  },
  uischema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/min_value",
        label: "",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.min_value.description,
        },
        layoutProps: {
          columns: 8,
        },
      },
      {
        type: "Control",
        scope: "#/properties/strict_min",
        label: "Strict",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.strict_min.description,
          style: {
            marginBottom: "24px",
          },
        },
        layoutProps: {
          columns: 2,
        },
      },
      {
        type: "Label",
        text: "AND",
        options: {
          type: "plain",
        },
        layoutProps: {
          columns: 1,
        },
      },
      {
        type: "Control",
        scope: "#/properties/max_value",
        label: "",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.max_value.description,
          style: {
            width: "100%",
          },
        },
        layoutProps: {
          columns: 8,
        },
      },
      {
        type: "Control",
        scope: "#/properties/strict_max",
        label: "Strict",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.strict_max.description,
        },
        layoutProps: {
          columns: 2,
        },
      },
    ],
  } satisfies UISchema<(typeof jsonSchemas.expectColumnMinToBeBetween.schema.properties.to_be_between.oneOf)[0]> & {
    type: "HorizontalLayout"
    elements: (UISchemaElement | PlaintextLabel)[]
  } as UISchemaElement,
}

export const WindowedFixedWithOffsetUISchemaRegistry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath) => {
    if (schemaPath === "#/properties/to_be_between" && schema.properties && "mostly" in schema.properties) {
      return 100
    }
    return -1
  },
  uischema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/mostly",
        options: {
          addonAfter: "%",
        },
        layoutProps: {
          columns: 24,
        },
      },
    ],
  } satisfies UISchema<(typeof jsonSchemas.expectColumnValuesToBeNull.schema.properties.to_be_between.oneOf)[0]> & {
    type: "HorizontalLayout"
    elements: (UISchemaElement | PlaintextLabel)[]
  } as UISchemaElement,
}

type PlaintextLabel = {
  type: "Label"
  text: string
  options: {
    type: AlertProps["type"] | "plain"
  }
  layoutProps: ColumnLayoutProps
}

export const WindowedRangeWithOffsetUISchemaRegistry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath) => {
    if (schemaPath === "#/properties/to_be_between" && schema.properties && "offset" in schema.properties) {
      return 100
    }
    return -1
  },
  uischema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        label: "",
        scope: "#/properties/offset",
        layoutProps: {
          columns: 4,
        },
      },
      {
        type: "Control",
        label: "",
        scope: "#/properties/percent",
        options: {
          addonAfter: "%",
        },
        layoutProps: {
          columns: 4,
        },
      },
      {
        type: "Label",
        text: "of the average of the last",
        options: {
          type: "plain",
        },
        layoutProps: {
          columns: 7,
        },
      },
      {
        type: "Control",
        label: "",
        scope: "#/properties/range",
        options: {
          addonAfter: "runs",
        },
        layoutProps: {
          columns: 5,
        },
      },
      {
        type: "Control",
        scope: "#/properties/strict",
        layoutProps: {
          columns: 3,
        },
      },
    ],
  } satisfies UISchema<(typeof jsonSchemas.expectColumnMinToBeBetween.schema.properties.to_be_between.oneOf)[1]> & {
    type: "HorizontalLayout"
    elements: (UISchemaElement | PlaintextLabel)[]
  } as UISchemaElement,
}

export const WindowedRangeWithFixedOffsetUISchemaRegistry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath) => {
    if (
      schemaPath === "#/properties/to_be_between" &&
      schema.properties &&
      !("offset" in schema.properties) &&
      !("mostly" in schema.properties)
    ) {
      return 100
    }
    return -1
  },
  uischema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        label: "",
        scope: "#/properties/percent",
        options: {
          addonAfter: "%",
        },
        layoutProps: {
          columns: 6,
        },
      },
      {
        type: "Label",
        text: "of the average of the last",
        options: {
          type: "plain",
        },
        layoutProps: {
          columns: 7,
        },
      },
      {
        type: "Control",
        label: "",
        scope: "#/properties/range",
        options: {
          addonAfter: "runs",
        },
        layoutProps: {
          columns: 11,
        },
      },
    ],
  } satisfies UISchema<(typeof jsonSchemas.expectColumnMinToBeBetween.schema.properties.to_be_between.oneOf)[1]> & {
    type: "HorizontalLayout"
    elements: (UISchemaElement | PlaintextLabel)[]
  } as UISchemaElement,
}

export const PlaintextRendererUISchemaRegistry: JsonFormsRendererRegistryEntry = {
  tester: (uischema) => {
    if (uischema.type === "Label" && uischema.options?.type === "plain") {
      return 100
    }
    return 0
  },
  renderer: withJsonFormsLabelProps(PlaintextRenderer),
}

function PlaintextRenderer({ text }: LabelProps & RendererProps) {
  return <p style={{ marginBottom: "24px", marginTop: "0px" }}>{text}</p>
}
