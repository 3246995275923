import { Descriptions } from "antd"
import { Preview } from "src/Expectation/Preview"
import { ExpectationRenderer } from "src/Expectation/ExpectationRenderer"
import { ExpectationInfoCard } from "src/ui/Card/Card"
import { useTheme } from "styled-components"
import { ExpectationMetaInfo } from "src/schemas/expectation-metadata-utils"

type ExpectationInformationProps = {
  metaInfo: ExpectationMetaInfo
  renderer?: ExpectationRenderer
}

export const ExpectationInformation = ({ renderer, metaInfo }: ExpectationInformationProps) => {
  const theme = useTheme()

  const preview = renderer ? <Preview renderer={renderer} /> : null

  const infoCardBody =
    metaInfo.description && renderer ? (
      <Descriptions
        column={1}
        layout="vertical"
        labelStyle={{ color: theme.colors.neutralColorPalette.blacks.colorText }}
      >
        <Descriptions.Item label="Description">{metaInfo.description}</Descriptions.Item>
        <Descriptions.Item label="Current Expectation">{preview}</Descriptions.Item>
      </Descriptions>
    ) : (
      metaInfo.description || preview
    )

  return (
    <ExpectationInfoCard title={metaInfo.title} category={metaInfo.category}>
      {infoCardBody}
    </ExpectationInfoCard>
  )
}
