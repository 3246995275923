import { Popover, Flex, Form, Segmented, Select, Alert, Tooltip } from "antd"
import { Button } from "src/ui/Button/Button"
import { useCallback, useState } from "react"
import { useTheme } from "styled-components"
import { graphql } from "src/api/graphql/gql"
import { useMutation, useQuery } from "@apollo/client"
import { DateSplitterMethodName, Metric } from "src/api/graphql/graphql"
import { UISplitterOptions } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { Heading3 } from "src/ui/typography/Text/Text"

export const EditBatchDataAssetWithLatestMetricRunDocument = graphql(`
  query EditBatchDataAssetWithLatestMetricRun($id: UUID!) {
    dataAsset(id: $id) {
      id
      latestMetricRun {
        metrics {
          columnDataType
          columnName
        }
      }
    }
  }
`)

export function EditBatchPopover({ dataAssetId, splitter }: { dataAssetId: string; splitter: UISplitterOptions }) {
  const [isOpen, setIsOpen] = useState(false)
  const { data, loading } = useQuery(EditBatchDataAssetWithLatestMetricRunDocument, {
    variables: {
      id: dataAssetId ?? "",
    },
    skip: !dataAssetId,
  })

  const dateTimeColumns =
    !loading && data?.dataAsset?.latestMetricRun?.metrics
      ? getDateTimeColumns(data?.dataAsset?.latestMetricRun?.metrics ?? [])
      : []

  const isDisabled = !dateTimeColumns.length || splitter === UISplitterOptions.unsupported

  return (
    <Popover
      placement="bottom"
      arrow={false}
      destroyTooltipOnHide
      title={
        <Flex align="center" justify="space-between">
          <Heading3>Edit Batch Definition</Heading3>
          <Button icon="close" type="text" aria-label="Close edit window" onClick={() => setIsOpen(false)} />
        </Flex>
      }
      content={
        <EditBatchForm onClose={() => setIsOpen(false)} dateTimeColumns={dateTimeColumns} dataAssetId={dataAssetId} />
      }
      trigger="click"
      open={isOpen}
      onOpenChange={() => setIsOpen(true)}
    >
      <Tooltip
        placement="right"
        title={isDisabled ? "Some text about this batch definition can only be edited via the API" : ""}
      >
        <Button
          type="text"
          aria-label="Edit Batch"
          icon="edit"
          onClick={() => setIsOpen(!isOpen)}
          disabled={isDisabled}
        />
      </Tooltip>
    </Popover>
  )
}

const getDateTimeColumns = (metrics: Metric[]) => {
  const isTimeBasedTypeRegex = "(TIME|DATE)"
  return metrics.filter((metric) => metric.columnDataType?.match(isTimeBasedTypeRegex))
}

export const EditBatchDefinitionMutationDocument = graphql(`
  mutation EditBatchDefinition($input: EditBatchDefinitionInput!) {
    editBatchDefinition(input: $input) {
      __typename
    }
  }
`)

type BatchForm = { batchInterval: DateSplitterMethodName; batchColumn: string }
type EditBatchFormProps = {
  onClose: () => void
  dateTimeColumns: Metric[]
  dataAssetId: string
}

export function EditBatchForm({ onClose, dateTimeColumns, dataAssetId }: EditBatchFormProps) {
  const theme = useTheme()
  const [editBatchDefinition, { loading, error }] = useMutation(EditBatchDefinitionMutationDocument)

  const handleFinish = useCallback(
    async (fields: BatchForm) => {
      if (!dataAssetId) {
        return // not a possibility; just assuring the compiler here
      }
      const { batchColumn, batchInterval } = fields
      const hasSplitter = Boolean(batchInterval.length)
      const splitterConfig = { columnName: batchColumn, methodName: batchInterval }

      await editBatchDefinition({
        variables: {
          input: {
            assetId: dataAssetId,
            // assuming that if the entire table is selected, then no splitter config is present
            ...(hasSplitter && { splitterConfig }),
          },
        },
      })
      onClose()
    },
    [dataAssetId, editBatchDefinition, onClose],
  )

  const intervalOptions = [
    { value: "", label: "Entire table" },
    { value: "SPLIT_ON_YEAR", label: UISplitterOptions.yearly },
    { value: "SPLIT_ON_YEAR_AND_MONTH", label: UISplitterOptions.monthly },
    { value: "SPLIT_ON_YEAR_AND_MONTH_AND_DAY", label: UISplitterOptions.daily },
  ]

  return (
    <>
      <Alert
        type="warning"
        style={{ marginBottom: theme.spacing.vertical.s, width: "410px" }}
        description="Some text about your history validation results might not make much sense after you update this."
      />
      <Form<BatchForm> style={{ width: "410px" }} onFinish={handleFinish}>
        <Form.Item name="batchInterval" label="Batch interval" required>
          <Segmented options={intervalOptions} />
        </Form.Item>
        <Form.Item name="batchColumn" label="Batch column" required>
          <Select
            options={dateTimeColumns.map((metric) => ({
              value: metric.columnName,
              label: metric.columnName,
            }))}
            placeholder="Select a DATE or DATETIME column"
          />
        </Form.Item>
        <Form.Item {...(error && { validateStatus: "error", help: error.message })}>
          <Flex justify="flex-end">
            <Button type="primary" loading={loading} htmlType="submit" aria-label="Update Batch">
              Save
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </>
  )
}
