import { useState, useCallback } from "react"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { Form } from "antd"
import { Drawer } from "src/ui/Drawer/Drawer"
import { useMutation, useQuery } from "@apollo/client"
import { LoadingState } from "src/ui/LoadingState"
import { graphql } from "src/api/graphql/gql"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking"
import { ExpectationEditorSimplified } from "src/Expectation/ExpectationEditorSimplified"
import { mapExpectationConfigToJsonConfig } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationDrawer/mapExpectationConfigurationToJsonConfig"
import { getExpectationMetaInfo } from "src/schemas/expectation-metadata-utils"
import { handleWindowedPayload } from "src/Expectation/CreateExpectationDrawer/windowedExpectationUtils"

export const ExpectationDrawer_DataAssetDocument = graphql(`
  query expectationDrawer_DataAsset($id: UUID!) {
    dataAsset(id: $id) {
      id
      __typename
      ...ExpectationEditor_DataAssetMetricRun
    }
  }
`)

export const EditExpectationDrawer_EditExpectationDocument = graphql(`
  mutation editExpectationDrawer_EditExpectation($input: UpdateExpectationV2Input!) {
    updateExpectationV2(input: $input) {
      expectation {
        geCloudId
        ...GetExpectationMetaInfo_Expectation
        # this is why we need fragment masking; it's very hard to tell what needs to be fetched on edit
        # TODO: refactor SimpleExpectationsTab to define a fragment we can include here
        domain {
          domainKwargs
          domainType
          id
          columns {
            name
            order
          }
        }
        geCloudId
        kwargs
        expectationType
        renderedContent {
          ...RenderedExpectation
        }
        validationResults(input: { limit: 1 }) {
          runTime
          success
        }
      }
    }
  }
`)

const EditExpectationDrawer_ExpectationFragmentDocument = graphql(`
  fragment EditExpectationDrawer_Expectation on ExpectationConfiguration {
    geCloudId
    ...MapExpectationConfigToJsonConfig_Expectation
    ...GetExpectationMetaInfo_Expectation
  }
`)

export type EditExpectationFragment = FragmentType<typeof EditExpectationDrawer_ExpectationFragmentDocument>

type Props = {
  open: boolean
  close: () => void
  dataAssetId: string
  expectation: EditExpectationFragment
}

export function SimpleEditExpectationDrawer({ open, dataAssetId, expectation: maskedExpectation, close }: Props) {
  const expectation = unmaskFragment(EditExpectationDrawer_ExpectationFragmentDocument, maskedExpectation)
  const [expectationConfiguration, setExpectationConfiguration] = useState<Record<string, unknown>>(
    mapExpectationConfigToJsonConfig(expectation),
  )
  const expectationMetaInfo = getExpectationMetaInfo({ expectation })
  const [updateExpectationMutation, { loading, error: updateExpectationError }] = useMutation(
    EditExpectationDrawer_EditExpectationDocument,
    {},
  )
  const [form] = Form.useForm()
  const saveExpectation = useCallback(async () => {
    await form
      .validateFields()
      .catch(() => {
        // nothing to do here; form validation will render error text as feedback
      })
      .then(async () => {
        if (!expectation.geCloudId || !expectationMetaInfo?.type) {
          return // compiler assurance
        }
        await updateExpectationMutation({
          variables: {
            input: {
              id: expectation.geCloudId,
              config: JSON.stringify(handleWindowedPayload(expectationConfiguration, expectationMetaInfo.type)),
            },
          },
        }) // todo: handle mutation errors here or in useMutation hook options
      })
    close()
  }, [
    close,
    updateExpectationMutation,
    expectation.geCloudId,
    expectationConfiguration,
    expectationMetaInfo?.type,
    form,
  ])

  const { data } = useQuery(ExpectationDrawer_DataAssetDocument, {
    variables: { id: dataAssetId },
    skip: !dataAssetId,
  })

  return (
    <Drawer
      title="Edit Expectation"
      placement="right"
      size="large"
      footer={
        <Drawer.Footer>
          <Drawer.FooterButton type="primary" onClick={saveExpectation} loading={loading}>
            Save
          </Drawer.FooterButton>
        </Drawer.Footer>
      }
      destroyOnClose
      open={open}
      onClose={close}
    >
      {expectationMetaInfo?.title ? (
        <Form form={form}>
          <ExpectationEditorSimplified
            value={expectationConfiguration}
            expectationMetaInfo={expectationMetaInfo}
            onChange={(newJson) => {
              setExpectationConfiguration(newJson)
            }}
            dataAsset={data?.dataAsset ?? undefined}
          />
          {updateExpectationError && (
            <AlertBanner message="Failed to save Expectation" description={updateExpectationError} />
          )}
        </Form>
      ) : (
        <LoadingState loading={loading} />
      )}
    </Drawer>
  )
}
