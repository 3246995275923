import { Form, message } from "antd"
import { Drawer, ScrollableFlex } from "src/ui/Drawer/Drawer"
import { useCallback, useState } from "react"
import { ApolloCache, ApolloError, FetchResult, useMutation } from "@apollo/client"
import { findIndex, isEqual } from "lodash-es"
import {
  DatasourcesWithRunsDocument,
  DatasourcesWithRunsQuery,
  UpdateDataSourceDocument,
  UpdateDataSourceMutation,
} from "src/api/graphql/graphql-operations"
import stringify from "json-stable-stringify"
import { JsonForm } from "src/DataAssets/connect-to-data/JsonForm"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { EditDataSourceDrawerHeader } from "src/DataAssets/connect-to-data/DataCRUDDrawerHeaders"
import {
  getDataSourceJsonSchemaMap,
  DataSourceUISchemaMap,
  SupportedDataSource,
  getUISchemaRegistryEntries,
} from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { GroupRendererRegistryEntry } from "src/jsonforms/layouts/GroupRenderer"

type Props = {
  data?: Record<string, unknown>
  onBack: () => void
  dataSource: { id: string; name: string }
  dataSourceType: SupportedDataSource
}

export function EditDataSource({ data: initialData, dataSource, onBack, dataSourceType }: Props) {
  const isHideQueryAssetEnabled = useIsFeatureEnabled("hideQueryAssetEnabled")

  const jsonSchema = getDataSourceJsonSchemaMap(isHideQueryAssetEnabled)[dataSourceType]
  const [page1UISchema] = DataSourceUISchemaMap[dataSourceType]

  const [data, setData] = useState<Record<string, unknown>>(initialData ?? {})
  const [dataSourceSaveError, setDataSourceSaveError] = useState<string>()
  const [form] = Form.useForm()

  const onChange = useCallback(
    (newData: Record<string, unknown>) => {
      if (!isEqual(data, newData)) {
        setData(newData)
      }
    },
    [data, setData],
  )

  const [updateDataSource, { reset: resetUpdateDataSourceMutation, loading }] = useMutation(UpdateDataSourceDocument, {
    update: updateCache,
    onError: (error: ApolloError) => {
      setDataSourceSaveError(error.message)
    },
    onCompleted: () => {
      // reset mutations?
      message.success(`Successfully saved Data Source`, MESSAGE_DURATION_SECONDS)
      onClose()
    },
  })
  const onClose = useCallback(() => {
    resetUpdateDataSourceMutation()
    setData({})
    form.resetFields()
    onBack()
  }, [form, onBack, resetUpdateDataSourceMutation])

  const onSubmit = useCallback(async () => {
    const formValidationResult = await form
      .validateFields()
      .then((values: Record<string, unknown>) => values)
      .catch((errorInfo: { errorFields: unknown[] }) => errorInfo)

    // annoying that AntD doesn't expose type of errorInfo
    if ("errorFields" in formValidationResult) {
      return // nothing to do; validateFields will have already rendered error messages on form fields
    }
    return updateDataSource({
      variables: {
        input: {
          id: dataSource.id,
          config: stringify(data),
        },
      },
    })
  }, [form, updateDataSource, dataSource.id, data])

  return (
    <>
      <ScrollableFlex vertical gap="middle">
        <EditDataSourceDrawerHeader dataSourceType={dataSourceType} dataSourceName={dataSource.name} />
        <Form form={form} layout="vertical">
          <JsonForm
            jsonSchema={jsonSchema}
            uiSchema={page1UISchema}
            uiSchemaRegistryEntries={getUISchemaRegistryEntries(dataSourceType)}
            data={data}
            customRendererRegistryEntries={[GroupRendererRegistryEntry]}
            updateData={onChange}
          />
          {dataSourceSaveError && (
            <AlertBanner description="There was an error saving your Data Source" message={dataSourceSaveError} />
          )}
        </Form>
      </ScrollableFlex>
      <Drawer.Footer>
        <Drawer.FooterButton
          type="primary"
          loading={loading}
          onClick={() => {
            return onSubmit()
          }}
        >
          Save
        </Drawer.FooterButton>
      </Drawer.Footer>
    </>
  )
}

function updateCache(cache: ApolloCache<unknown>, result: FetchResult<UpdateDataSourceMutation>) {
  const dataSource = result.data?.updateDatasource?.datasourceV2
  cache.updateQuery(
    { query: DatasourcesWithRunsDocument, broadcast: true },
    (cachedQuery: DatasourcesWithRunsQuery | null) => {
      if (!dataSource || !cachedQuery) {
        return undefined
      }
      const indexOfDataSource = findIndex(cachedQuery.datasourcesV2, ({ id }) => id === dataSource.id)
      if (!(indexOfDataSource >= 0)) {
        return undefined
      }

      return {
        ...cachedQuery,
        datasourcesV2: [
          ...cachedQuery.datasourcesV2.slice(0, indexOfDataSource),
          dataSource,
          ...cachedQuery.datasourcesV2.slice(indexOfDataSource + 1),
        ],
      }
    },
  )
}
