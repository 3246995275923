import { message } from "antd"
import { useCallback, useState } from "react"

import { graphql } from "src/api/graphql/gql"
import { useMutation } from "@apollo/client"
import { Button } from "src/ui/Button/Button"
import { DeleteResourceModal } from "src/ui/DeleteResourceModal/DeleteResourceModal"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { ExpectationsTab_GetExpectationsDocument } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationsTab"

export const DeleteExpectationModal_DeleteExpectationDocument = graphql(`
  mutation DeleteExpectationModal_DeleteExpectation($input: DeleteExpectationV2Input!) {
    deleteExpectationV2(input: $input) {
      id
    }
  }
`)

export const DeleteExpectationModal = ({
  expectationId,
  assetId,
}: {
  expectationId: string | null
  assetId: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [deleteExpectationMutation, { loading, error }] = useMutation(
    DeleteExpectationModal_DeleteExpectationDocument,
    {
      onCompleted: () => {
        message.success(`Successfully deleted Expectation`, MESSAGE_DURATION_SECONDS)
        setIsOpen(false)
      },
      onError: (error) => {
        console.error("Error deleting Expectation", error)
      },
      refetchQueries: [{ query: ExpectationsTab_GetExpectationsDocument, variables: { input: assetId } }],
    },
  )

  const deleteExpectation = useCallback(async () => {
    if (!expectationId) return

    await deleteExpectationMutation({
      variables: { input: { expectationId } },
    })
  }, [deleteExpectationMutation, expectationId])

  return (
    <>
      <Button
        type="text"
        title="Delete Expectation"
        aria-label="Delete Expectation"
        icon="trash"
        disabled={!expectationId || loading}
        onClick={() => setIsOpen(!isOpen)}
      />
      <DeleteResourceModal
        open={isOpen}
        name="Expectation"
        resourceTitle="Expectation"
        onCancel={() => setIsOpen(false)}
        okButtonProps={{
          loading,
        }}
        onOk={() => deleteExpectation()}
        errorMessage={error?.message}
      />
    </>
  )
}
