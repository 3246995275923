/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Grid, Progress } from "antd"
import styled, { useTheme } from "styled-components"
import { ValidationResultCaptionRegular } from "src/Expectation/utils"
import Tooltip from "src/ui/Tooltip/Tooltip"
import { formatNumber } from "src/common/utils/formatNumber"

const SuccessMeterBox = styled.div<{ $xs?: boolean }>`
  max-width: ${({ $xs }) => ($xs ? "85%" : "30%")};
  display: flex;
`

interface SuccessMeterProps {
  // observedPercent is the percent of the bar to fill
  observedPercent: number
  // success displays green if true, red if false
  success: boolean
  // elementCount is the total number of data points/rows examined
  elementCount?: number
  // successCount is the number of points/rows that passed our expectation checks.
  successCount?: number
}

export function formatPercent(percent?: number): string | undefined {
  if (percent === undefined) {
    return undefined
  }
  if (Number.isInteger(percent)) {
    return String(percent) + "%"
  }
  if (Math.abs(percent) > 0 && Math.abs(percent) < 1) {
    return percent.toLocaleString(undefined, { maximumSignificantDigits: 2 }) + "%"
  }
  return percent.toLocaleString(undefined, { maximumFractionDigits: 2 }) + "%"
}

export function SuccessMeter(props: SuccessMeterProps) {
  const { observedPercent, success, elementCount, successCount } = props
  const renderedElementCount = elementCount ? formatNumber(elementCount) : undefined
  const renderedSuccessCount = successCount ? formatNumber(successCount) : undefined
  const wrapWithTooltip: boolean = renderedElementCount !== undefined && renderedSuccessCount !== undefined
  const tooltipTitle = `Row Count: ${renderedSuccessCount} / ${renderedElementCount} (${observedPercent}%)`
  const theme = useTheme()
  const { xs } = Grid.useBreakpoint()
  const strokeColor = success ? theme.colors.success.gxSuccess : theme.colors.error.gxError

  const box = (
    <SuccessMeterBox $xs={xs}>
      <ValidationResultCaptionRegular>Success Rate:</ValidationResultCaptionRegular>
      <Progress
        percent={observedPercent}
        format={formatPercent}
        strokeColor={strokeColor}
        size="small"
        aria-label="success rate"
        status="normal"
      />
    </SuccessMeterBox>
  )

  if (wrapWithTooltip) {
    return <Tooltip title={tooltipTitle}>{box}</Tooltip>
  }

  return box
}
