import { useEffect, useMemo } from "react"
import { graphql } from "src/api/graphql/gql"
import { ExpectationRenderer } from "src/Expectation/ExpectationRenderer"
import { ExpectationInformation } from "src/Expectation/ExpectationInformation"
import { ExpectationConfigForm } from "src/Expectation/uiForms/ExpectationConfigForm"
import { ExpectationMetaInfo } from "src/schemas/expectation-metadata-utils"
import {
  getJsonSchemaBySnakeCaseName,
  getJsonTemplateFromJsonSchemaSimplified,
  removeAdvancedKwargs,
} from "src/Expectation/utils"
import { FragmentType, unmaskFragment } from "src/api/graphql"

type Props = {
  value: Record<string, unknown>
  onChange: (newValue: Record<string, unknown>) => void
  expectationMetaInfo: ExpectationMetaInfo
  dataAsset?: FragmentType<typeof ExpectationEditor_DataAssetMetricRunFragmentDoc>
  renderer?: ExpectationRenderer
}

const ExpectationEditor_DataAssetMetricRunFragmentDoc = graphql(`
  fragment ExpectationEditor_DataAssetMetricRun on AssetRef {
    id
    latestMetricRun {
      dataAssetId
      metrics {
        columnDataType
        columnName
        mean
        median
        nullCount
        valueRangeMax
        valueRangeMin
        valueRangeMaxUnion {
          __typename
          ...MetricValueStringTypeFragment
          ...MetricValueFloatTypeFragment
        }
        valueRangeMinUnion {
          __typename
          ...MetricValueStringTypeFragment
          ...MetricValueFloatTypeFragment
        }
      }
      rowCount
    }
  }
`)

export function ExpectationEditorSimplified({ value, onChange, expectationMetaInfo, dataAsset, renderer }: Props) {
  const jsonSchema = useMemo(
    () => (expectationMetaInfo.type ? getJsonSchemaBySnakeCaseName(expectationMetaInfo.type) : undefined),
    [expectationMetaInfo.type],
  )

  const jsonTemplate = getJsonTemplateFromJsonSchemaSimplified(jsonSchema)

  /**
   * Set Initial Editor Value when value is falsy on Mount
   */
  useEffect(() => {
    if (!value && jsonTemplate) {
      onChange(jsonTemplate)
    }
  }, [jsonTemplate, onChange, value])

  if (!jsonSchema) {
    return null // this is not a realistic case; it's just hard to assure the compiler given the way that schema lookups are typed at the moment
  }
  return (
    <div>
      <ExpectationInformation metaInfo={expectationMetaInfo} renderer={renderer} />
      <ExpectationConfigForm
        key={expectationMetaInfo.type}
        value={value}
        jsonSchema={jsonSchema}
        onChange={({ data }) => {
          if (data) {
            delete data.expectation_type
            onChange(removeAdvancedKwargs(data))
          }
        }}
        config={{
          dataAssetWithLatestMetricRun: unmaskFragment(ExpectationEditor_DataAssetMetricRunFragmentDoc, dataAsset),
        }}
      />
    </div>
  )
}
