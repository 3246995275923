/* eslint-disable react-refresh/only-export-components */ // FIXME
import { withJsonFormsControlProps } from "@jsonforms/react"
import {
  ControlElement,
  ControlProps,
  JsonFormsRendererRegistryEntry,
  rankWith,
  scopeEndsWith,
  or,
} from "@jsonforms/core"
import { Form, Input, Select } from "antd"
import { Metric } from "src/api/graphql/graphql"
import { ChangeEvent, useEffect, useMemo } from "react"
import { startCase } from "lodash-es"

export const ColumnNameRegistryEntry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(
    90, //increase rank as needed
    or(
      scopeEndsWith("#/properties/column"),
      scopeEndsWith("#/properties/column_A"),
      scopeEndsWith("#/properties/column_B"),
    ),
  ),
  renderer: withJsonFormsControlProps(ColumnNameControl),
}

function ColumnNameControl({ handleChange, config, path, data, required, uischema }: ControlProps) {
  const options = useMemo(
    () =>
      config.dataAssetWithLatestMetricRun?.latestMetricRun?.metrics.map((metric: Metric) => ({
        value: metric.columnName,
        label: metric.columnName,
      })),
    [config],
  )

  const form = Form.useFormInstance()

  // using this mostly to set the initial value https://github.com/ant-design/ant-design/issues/22372
  useEffect(() => {
    form.setFieldsValue({ [path]: data })
  }, [data, path, form])

  const label = startCase(path.split(".").pop())
  return (
    <Form.Item
      name={path}
      label={label}
      tooltip={
        (
          uischema as ControlElement & {
            formItemProps: {
              tooltip?: string
            }
          }
        )?.formItemProps?.tooltip
      }
      rules={[
        {
          required: required,
          message: "Column name is required",
        },
      ]}
      validateTrigger="onBlur"
    >
      {options && (
        <>
          <Select
            showSearch
            placeholder="Select a column"
            aria-label={`Select ${label}`}
            options={options}
            onChange={(option) => {
              handleChange(path, option)
            }}
            filterOption={(inputValue, option) => {
              const optionValue = option?.value?.toString() || ""
              return optionValue.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }}
          />
        </>
      )}
      {!options && (
        <Input
          placeholder="Enter a column name"
          aria-label={label}
          autoComplete="off"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleChange(path, event.target.value)
          }}
        />
      )}
    </Form.Item>
  )
}
