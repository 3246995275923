import { Descriptions, DescriptionsProps } from "antd"
import { Param } from "src/ui/Param/Param"
import { useGetSplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"
import { pluralize } from "src/common/utils/strings"

export const BatchDefinitionDescription: React.FC<DescriptionsProps> = ({ style, layout = "vertical" }) => {
  const { splitter, splitterColumns, isSplitterSupported } = useGetSplitterData({ isVisible: true })

  const column = pluralize("column", splitterColumns?.split(",").length || 1)
  const batchColumnLabel = `Batch ${column}`

  return (
    <Descriptions style={{ ...style }} column={5} layout={layout}>
      <Descriptions.Item label="Batch interval">{isSplitterSupported ? splitter : "Unsupported"}</Descriptions.Item>
      <Descriptions.Item label={batchColumnLabel}>
        <Param>{splitterColumns}</Param>
      </Descriptions.Item>
    </Descriptions>
  )
}
