import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"
import { get } from "lodash-es"
import { FragmentType, unmaskFragment, graphql } from "src/api/graphql"
import { typedCamelCase } from "src/common/utils/camelCase"

import { ExpectationCategory } from "src/schemas/expectation-metadata-category"
import { GetExpectationMetaInfo_ExpectationFragment } from "src/api/graphql/graphql"

// differentiate supported vs what gets a card
export const SupportedExpectationList = [
  "expectColumnDistinctValuesToBeInSet",
  "expectColumnDistinctValuesToContainSet",
  "expectColumnDistinctValuesToEqualSet",
  "expectColumnMaxToBeBetween",
  "expectColumnMeanToBeBetween",
  "expectColumnMedianToBeBetween",
  "expectColumnMinToBeBetween",
  "expectColumnMostCommonValueToBeInSet",
  "expectColumnPairValuesAToBeGreaterThanB",
  "expectColumnPairValuesToBeEqual",
  "expectColumnProportionOfUniqueValuesToBeBetween",
  "expectColumnStdevToBeBetween",
  "expectColumnSumToBeBetween",
  "expectColumnToExist",
  "expectColumnUniqueValueCountToBeBetween",
  "expectColumnValueLengthsToBeBetween",
  "expectColumnValueLengthsToEqual",
  "expectColumnValuesToBeBetween",
  "expectColumnValuesToBeInSet",
  "expectColumnValuesToBeInTypeList",
  "expectColumnValuesToBeNull",
  "expectColumnValuesToBeOfType",
  "expectColumnValuesToBeUnique",
  "expectColumnValuesToMatchLikePattern",
  "expectColumnValuesToMatchLikePatternList",
  "expectColumnValuesToMatchRegex",
  "expectColumnValuesToMatchRegexList",
  "expectColumnValuesToNotBeInSet",
  "expectColumnValuesToNotBeNull",
  "expectColumnValuesToNotMatchLikePattern",
  "expectColumnValuesToNotMatchLikePatternList",
  "expectColumnValuesToNotMatchRegex",
  "expectColumnValuesToNotMatchRegexList",
  "expectColumnValueZScoresToBeLessThan",
  "expectCompoundColumnsToBeUnique",
  "expectMulticolumnSumToEqual",
  "expectSelectColumnValuesToBeUniqueWithinRecord",
  "expectTableColumnCountToBeBetween",
  "expectTableColumnCountToEqual",
  "expectTableColumnsToMatchOrderedList",
  "expectTableColumnsToMatchSet",
  "expectTableRowCountToBeBetween",
  "expectTableRowCountToEqual",
  "expectTableRowCountToEqualOtherTable",
  "unexpectedRowsExpectation",
] as const satisfies Array<CamelCasedExpectationType>

export type SupportedExpectation = (typeof SupportedExpectationList)[number]

export type UnsupportedExpectation =
  | "expectColumnKlDivergenceToBeLessThan"
  | "expectColumnPairValuesToBeInSet"
  | "expectColumnQuantileValuesToBeBetween"
  | "expectColumnValuesToBeDateutilParseable"
  | "expectColumnValuesToBeDecreasing"
  | "expectColumnValuesToBeIncreasing"
  | "expectColumnValuesToBeJsonParseable"
  | "expectColumnValuesToMatchJsonSchema"
  | "expectColumnValuesToMatchStrftimeFormat"

type ThisIsNeverIfAllExpectationTypesAreEqualToTheUnionOfUnsupportedAndSupportedTypes =
  Exclude<CamelCasedExpectationType, UnsupportedExpectation | SupportedExpectation> extends never
    ? never
    : Exclude<CamelCasedExpectationType, UnsupportedExpectation | SupportedExpectation> | true

/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-expect-error if we can assign anOrphanedExpectationTypeExists to true, it means an expectation type has not been captured in either the Unsupported or Supported types; modify those types to ensure this assignment errors (and is caught by ts-expect-error)
const anOrphanedExpectationTypeExists: ThisIsNeverIfAllExpectationTypesAreEqualToTheUnionOfUnsupportedAndSupportedTypes =
  true
/* eslint-enable @typescript-eslint/no-unused-vars */

type ExpectationJsonSchemaObject = typeof jsonSchemas

export type CamelCasedExpectationType = keyof ExpectationJsonSchemaObject

export const categoryPath = "properties.metadata.properties.data_quality_issues.const.0"

export type SnakeCasedExpectationType = {
  [P in keyof ExpectationJsonSchemaObject]: ExpectationJsonSchemaObject[P]["schema"]["properties"]["metadata"]["properties"]["expectation_type"]["const"]
}[keyof ExpectationJsonSchemaObject]

export type ExpectationTitle = {
  [P in keyof ExpectationJsonSchemaObject]: ExpectationJsonSchemaObject[P]["schema"]["title"]
}[keyof ExpectationJsonSchemaObject]

export type ExpectationMetaInfo = {
  category?: ExpectationCategory
  title: ExpectationTitle
  type: SnakeCasedExpectationType
  description?: string
}

const GetExpectationMetaInfo_ExpectationFragmentDocument = graphql(`
  fragment GetExpectationMetaInfo_Expectation on ExpectationConfiguration {
    expectationType
  }
`)

export function getExpectationMetaInfo({
  expectation: maskedExpectation,
}: {
  expectation: FragmentType<typeof GetExpectationMetaInfo_ExpectationFragmentDocument> | null
}): ExpectationMetaInfo | null {
  const expectation = unmaskFragment(GetExpectationMetaInfo_ExpectationFragmentDocument, maskedExpectation)
  if (!expectation?.expectationType) {
    return null
  }
  const type = getCamelCasedTypeNameFromGQLExpectationType(expectation)
  if (!type) {
    console.error(`Unrecognized expectation type "${expectation.expectationType}"`)
    return null
  }
  return getExpectationMetaInfoFromType(type)
}

export function getExpectationMetaInfoFromType(type: CamelCasedExpectationType): ExpectationMetaInfo {
  const category = get(jsonSchemas, `${type}.schema.${categoryPath}`)
  const title = get(jsonSchemas, `${type}.schema.title`)
  const snakeCasedType = get(jsonSchemas, `${type}.schema.properties.metadata.properties.expectation_type.const`)
  const description = get(jsonSchemas, `${type}.schema.properties.metadata.properties.short_description.const`)

  return { category, title, type: snakeCasedType, description }
}

function getCamelCasedTypeNameFromGQLExpectationType(
  expectation: GetExpectationMetaInfo_ExpectationFragment,
): CamelCasedExpectationType | null {
  const camelCasedName = typedCamelCase(expectation.expectationType as SnakeCasedExpectationType)
  const jsonSchemaExpectationTypeName = get(
    jsonSchemas,
    `${camelCasedName}.schema.properties.metadata.properties.expectation_type.const`,
  )

  if (expectation.expectationType !== jsonSchemaExpectationTypeName) {
    console.error(`Unrecognized expectation type ${expectation.expectationType}`)
    return null
  }
  return camelCasedName
}
