import {
  Card,
  CardProps,
  Empty,
  EmptyProps,
  Radio,
  RadioGroupProps,
  theme as antdTheme,
  Row,
  Col,
  GlobalToken,
} from "antd"
import Meta, { CardMetaProps } from "antd/es/card/Meta"
import styled, { useTheme } from "styled-components"

const { useToken } = antdTheme

// Note: We are combining the Radio and Card components to create a RadioCard components, hence the style overrides in this file
const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  .ant-radio-button-wrapper-checked {
    z-index: auto;

    :hover {
      border-color: ${({ theme }) => theme.colors.primaryColors.gxAccentMedium};
    }
  }
`

const StyledRadio = styled(Radio)`
  margin: 0;
  padding: 0;
  height: 100%;
`

const StyledTitle = styled.div`
  white-space: normal;
  padding-top: ${({ theme }) => theme.spacing.vertical.xxs};
  padding-bottom: ${({ theme }) => theme.spacing.vertical.xxxs};
`

// AntDs Card doesn't have a disabled state, so we need specify these specific styles,
// and we want to use AntDs default disabled color var
const StyledCard = styled(Card)<{ $antdToken: GlobalToken; $disabled?: boolean }>`
  border: none;
  background-color: ${({ $antdToken, $disabled, theme }) =>
    $disabled ? $antdToken.colorBgContainerDisabled : theme.colors.neutralColorPalette.whites.white};
  height: 100%;

  &.ant-card-hoverable:hover {
    cursor: ${({ $disabled }) => $disabled && "not-allowed"};
    box-shadow: ${({ $disabled }) => $disabled && "none"};
  }
`

export interface RadioCardProps extends CardProps {
  value?: string
  disabled?: boolean
  meta?: CardMetaProps
}

function RadioCard({ title, ...props }: RadioCardProps) {
  const theme = useTheme()
  const { token } = useToken()

  return (
    <StyledRadio
      value={props.value}
      disabled={props.disabled}
      style={{ borderRadius: theme.spacing.cornerRadius.medium, width: "100%" }}
      onClick={props.onClick}
    >
      <StyledCard
        aria-label={props.value}
        size="small"
        bodyStyle={{
          fontSize: theme.typography.fontSize.extraSmall,
        }}
        headStyle={{
          border: "None",
          fontSize: theme.typography.fontSize.small,
        }}
        $antdToken={token}
        $disabled={props.disabled}
        title={<StyledTitle>{title}</StyledTitle>}
        {...props}
      >
        <Meta {...props.meta} />
      </StyledCard>
    </StyledRadio>
  )
}

interface RadioCardGroupProps extends RadioGroupProps {
  cards: RadioCardProps[]
  cardsColSpan?: number
  emptyState?: EmptyProps
}

function RadioCardGroup(props: RadioCardGroupProps) {
  return (
    <StyledRadioGroup optionType="button" {...props}>
      {props.cards.length > 0 && (
        <Row gutter={[16, 16]}>
          {props.cards.map((card) => (
            <Col span={props.cardsColSpan || 12} key={card.value}>
              <RadioCard {...card} key={card.value} />
            </Col>
          ))}
        </Row>
      )}
      {props.emptyState && props.cards.length === 0 && <Empty {...props.emptyState} />}
    </StyledRadioGroup>
  )
}
export { RadioCard, RadioCardGroup }
