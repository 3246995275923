/* eslint-disable react-refresh/only-export-components */ // FIXME
import { useQuery, makeVar, useReactiveVar } from "@apollo/client"
import { Tabs } from "antd"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { assertNever } from "src/DataAssets/utils"
import { ExpectationSuitesDocument } from "src/api/graphql/graphql-operations"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { useOrganizationSlug } from "src/organizations/useOrganizationSlug"
import { Heading3, LG } from "src/ui/typography/Text/Text"

export type DataAssetDetailTabKey = "expectations" | "validations" | "overview"

type DataAssetTabsProps = {
  expectationSuiteId?: string
  currentTab: DataAssetDetailTabKey
}

/**
 * As part of the first-time experience creating an expectation on a Data Asset
 * we also create a default expectation suite. This reactive variable
 * allows us to trigger a refetch from a child component not directly in
 * this <Tab>'s rendering tree, thus maintaining consistency with available
 * suites.
 */
export const refetchDataAssetExpectationSuites = makeVar(false)

export function DataAssetTabs(props: DataAssetTabsProps) {
  const { navigateInOrg } = useOrganizationSlug()
  const { assetId: _assetId = "" } = useParams<{
    assetId: string
  }>()
  const sujeEnabled = useIsFeatureEnabled("sujeEnabled")

  const assetId = decodeURIComponent(_assetId)

  const shouldRefetch = useReactiveVar(refetchDataAssetExpectationSuites)

  const { data, loading, refetch } = useQuery(ExpectationSuitesDocument, {
    variables: { options: { filterByAssetRefId: assetId } },
    skip: !assetId || !!props.expectationSuiteId || sujeEnabled,
  })
  const selectedExpectationSuiteId = props.expectationSuiteId ?? data?.expectationSuitesV2[0]?.id

  useEffect(() => {
    if (shouldRefetch) {
      refetch()

      // make sure to reset the reactive var so we don't continually refetch
      refetchDataAssetExpectationSuites(false)
    }
  }, [shouldRefetch, refetch])

  if (loading) {
    return null
  }

  return (
    <Tabs
      activeKey={props.currentTab}
      onChange={(value: string) => {
        const key: DataAssetDetailTabKey = value as DataAssetDetailTabKey
        switch (key) {
          case "expectations":
            sujeEnabled
              ? navigateInOrg(`/data-assets/${assetId}/${key}`)
              : navigateInOrg(`/data-assets/${assetId}/${key}/expectation-suites/${selectedExpectationSuiteId}`)
            break
          case "overview":
            navigateInOrg(`/data-assets/${assetId}/overview`)
            break
          case "validations":
            navigateInOrg(`/data-assets/${assetId}/${key}/expectation-suites/${selectedExpectationSuiteId}/results`)
            break
          default:
            try {
              // assert never so that TypeScript will fail to compile should the DataAssetDetailTabKey union expand
              assertNever(key)
            } catch (e) {
              console.error(`Invalid AssetDetailTabKey "${key}"`)
            }
            navigateInOrg(`/data-assets/${assetId}/overview`)
        }
      }}
      tabBarStyle={{ marginBottom: 0 }}
      items={[
        {
          label: props.currentTab === "overview" ? <Heading3>Overview</Heading3> : <LG>Overview</LG>,
          key: "overview",
          disabled: loading,
        },
        {
          label: props.currentTab === "expectations" ? <Heading3>Expectations</Heading3> : <LG>Expectations</LG>,
          key: "expectations",
          disabled: sujeEnabled ? false : loading || !selectedExpectationSuiteId,
        },
        {
          label: props.currentTab === "validations" ? <Heading3>Validations</Heading3> : <LG>Validations</LG>,
          key: "validations",
          disabled: loading || !selectedExpectationSuiteId,
        },
      ]}
    />
  )
}
