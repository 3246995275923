import { cloneDeep } from "lodash-es"
import {
  ExpectationConfig,
  MostlyWindow,
  OffsetWindow,
  WindowConfig,
  Window,
} from "src/Expectation/CreateExpectationDrawer/types"
import { SnakeCasedExpectationType } from "src/schemas/expectation-metadata-utils"

export const createWindow = (config: WindowConfig, minMax: string, selectedExpectation: string): Window => ({
  constraint_fn: "mean",
  parameter_name: `${selectedExpectation}_${minMax}`,
  range: config.range,
  offset: {
    positive: config.percent,
    negative: config.percent,
  },
})

export const handleOffsetWindow = (
  config: ExpectationConfig,
  toBeBetween: OffsetWindow,
  selectedExpectation: SnakeCasedExpectationType,
) => {
  config.min_value = config.max_value = null
  const windows: Window[] = []

  if (toBeBetween.offset.includes("-")) {
    windows.push(createWindow(toBeBetween, "min", selectedExpectation))
    config.min_value = { $PARAMETER: `${selectedExpectation}_min` }
  }
  if (toBeBetween.offset.includes("+")) {
    windows.push(createWindow(toBeBetween, "max", selectedExpectation))
    config.max_value = { $PARAMETER: `${selectedExpectation}_max` }
  }

  config.windows = windows

  return config
}

export const handleMostlyWindow = (
  config: ExpectationConfig,
  toBeBetween: MostlyWindow,
  selectedExpectation: SnakeCasedExpectationType,
) => {
  if ("percent" in toBeBetween) {
    // "percent" and "mostly" are mutually exclusive in these cases
    return {
      ...config,
      mostly: { $PARAMETER: `${selectedExpectation}_min` },
      windows: [createWindow(toBeBetween, "min", selectedExpectation)],
    }
  }
  return { ...config, ...(toBeBetween as object) }
}

export const handleWindowedPayload = (configObj: ExpectationConfig, selectedExpectation: SnakeCasedExpectationType) => {
  const config = cloneDeep(configObj)
  if (!config.to_be_between) {
    // If a standard expectation, we need to make sure the windows prop does not exist
    delete config.windows
    return config
  }

  const { to_be_between } = config
  delete config.to_be_between

  if ("offset" in to_be_between && "range" in to_be_between) {
    return handleOffsetWindow(config, to_be_between as OffsetWindow, selectedExpectation)
  }
  if ("mostly" in to_be_between) {
    return handleMostlyWindow(config, to_be_between as MostlyWindow, selectedExpectation)
  }

  // Another instance where we may need to delete config.windows, i.e.
  // A dynamic expectation where the user may have opted to use its fixed variant
  delete config.windows

  // if neither of the above conditions are met, return the object as it was
  return { ...config, ...to_be_between }
}
