import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"
import { baseUISchemaElements } from "src/Expectation/uiForms/schemas/base-ui-schema-elements"

export const expectWindowedColumnMinToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.column.description,
      },
      layoutProps: {
        columns: 24,
      },
    },
    {
      type: "Control",
      label: {
        type: "Text",
        text: "to be between",
      },
      scope: "#/properties/to_be_between",
      options: {
        optionType: "dropdown",
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMinToBeBetween.schema>

export const expectWindowedColumnMaxToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMaxToBeBetween.schema.properties.column.description,
      },
      layoutProps: {
        columns: 24,
      },
    },
    {
      type: "Control",
      label: {
        type: "Text",
        text: "to be between",
      },
      scope: "#/properties/to_be_between",
      options: {
        optionType: "dropdown",
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMaxToBeBetween.schema>

export const expectWindowedColumnMeanToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMeanToBeBetween.schema.properties.column.description,
      },
      layoutProps: {
        columns: 24,
      },
    },
    {
      type: "Control",
      label: {
        type: "Text",
        text: "to be between",
      },
      scope: "#/properties/to_be_between",
      options: {
        optionType: "dropdown",
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMeanToBeBetween.schema>

export const expectWindowedColumnMedianToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMedianToBeBetween.schema.properties.column.description,
      },
      layoutProps: {
        columns: 24,
      },
    },
    {
      type: "Control",
      label: {
        type: "Text",
        text: "to be between",
      },
      scope: "#/properties/to_be_between",
      options: {
        optionType: "dropdown",
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMedianToBeBetween.schema>

export const expectWindowedColumnSumToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnSumToBeBetween.schema.properties.column.description,
      },
      layoutProps: {
        columns: 24,
      },
    },
    {
      type: "Control",
      label: {
        type: "Text",
        text: "to be between",
      },
      scope: "#/properties/to_be_between",
      options: {
        optionType: "dropdown",
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnSumToBeBetween.schema>

export const expectWindowedTableRowCountToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      label: {
        type: "Text",
        text: "to be between",
      },
      scope: "#/properties/to_be_between",
      options: {
        optionType: "dropdown",
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectTableRowCountToBeBetween.schema>

export const expectWindowedTableColumnCountToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      label: {
        type: "Text",
        text: "to be between",
      },
      scope: "#/properties/to_be_between",
      options: {
        optionType: "dropdown",
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectTableColumnCountToBeBetween.schema>

export const expectWindowedColumnStdevToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnStdevToBeBetween.schema.properties.column.description,
      },
      layoutProps: {
        columns: 24,
      },
    },
    {
      type: "Control",
      label: {
        type: "Text",
        text: "to be between",
      },
      scope: "#/properties/to_be_between",
      options: {
        optionType: "dropdown",
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnStdevToBeBetween.schema>

export const expectWindowedColumnUniqueValueCountToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema.properties.column.description,
      },
      layoutProps: {
        columns: 24,
      },
    },
    {
      type: "Control",
      label: {
        type: "Text",
        text: "to be between",
      },
      scope: "#/properties/to_be_between",
      options: {
        optionType: "dropdown",
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema>

export const expectWindowedColumnProportionOfUniqueValuesToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema.properties.column.description,
      },
      layoutProps: {
        columns: 24,
      },
    },
    {
      type: "Control",
      label: {
        type: "Text",
        text: "to be between",
      },
      scope: "#/properties/to_be_between",
      options: {
        optionType: "dropdown",
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema>

// TODO: missingness dynamic parameters
// export const expectWindowedColumnValuesToBeNull = {
//   type: "VerticalLayout",
//   elements: [
//     {
//       type: "Control",
//       scope: "#/properties/column",
//       formItemProps: {
//         tooltip: jsonSchemas.expectColumnValuesToBeNull.schema.properties.column.description,
//       },
//       layoutProps: {
//         columns: 24,
//       },
//     },
//     {
//       type: "Control",
//       label: {
//         type: "Text",
//         text: "to have at least",
//       },
//       scope: "#/properties/to_be_between",
//       options: {
//         optionType: "dropdown",
//       },
//     },
//     ...baseUISchemaElements,
//   ],
// } satisfies UISchema<typeof jsonSchemas.expectColumnValuesToBeNull.schema>

// export const expectWindowedColumnValuesToNotBeNull = {
//   type: "VerticalLayout",
//   elements: [
//     {
//       type: "Control",
//       scope: "#/properties/column",
//       formItemProps: {
//         tooltip: jsonSchemas.expectColumnValuesToNotBeNull.schema.properties.column.description,
//       },
//       layoutProps: {
//         columns: 24,
//       },
//     },
//     {
//       type: "Control",
//       label: {
//         type: "Text",
//         text: "to have at least",
//       },
//       scope: "#/properties/to_be_between",
//       options: {
//         optionType: "dropdown",
//       },
//     },
//     ...baseUISchemaElements,
//   ],
// } satisfies UISchema<typeof jsonSchemas.expectColumnValuesToNotBeNull.schema>
