import { useState, useMemo, ReactNode } from "react"
import { StringValueType } from "src/api/graphql/graphql"
import { Button } from "src/ui/Button/Button"
import styled, { css } from "styled-components"
import { Tag } from "src/ui/Tag"
import { ParamHistory, ParamTypes } from "src/Expectation/Expectation"
import { addDynamicStyledText, WindowTooltips } from "src/Expectation/utils/addDynamicStyledText"
import { formatNumber } from "src/common/utils/formatNumber"
import { Param, UpdatedParam } from "src/ui/Param/Param"
import { CodeSnippetEditor } from "src/ui/CodeSnippetEditor/CodeSnippetEditor"
import { ExpandableContainer } from "src/ui/ExpandableContainer"
import { isEmpty } from "lodash-es"
import { windowedExpectationTemplates, WindowedExpectation } from "src/common/config"
import type { Window } from "src/Expectation/CreateExpectationDrawer/types"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { Tooltip } from "antd"

const StyledExpectation = styled.div<{ $isExpectationDeleted?: boolean; $isDeletionAction?: boolean }>`
  ${({ $isDeletionAction }) => css`
    ${$isDeletionAction &&
    css`
      text-decoration: line-through;
    `}
  `}
`

const StyledExpectationWithCodeBlock = styled(StyledExpectation)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`

const SeeAllButton = styled(Button)`
  display: inline-flex;
`

const CodeBlockContainer = styled(ExpandableContainer)`
  width: 100%;
  & > header {
    background: rgb(0 0 0 / 2%);
    padding: 12px 16px;
    & > div > div > span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
  & > div {
    padding: ${({ expanded }) => (expanded ? "16px" : "0")};
  }
`

function formatStringNumber(input: string): boolean {
  if (isNaN(Number(input)) || input.length > 16) {
    return true
  }
  return false
}

const formatLaguage = (key: string): string => {
  switch (key) {
    case "sql":
      return "SQL"
    case "python":
      return "Python"
    case "yaml":
      return "YAML"
    case "json":
      return "JSON"
    default:
      return key
  }
}

// TODO: template should be populated with description, but currently is not
// when it is, we can remove this function
const parseTemplate = (template: string | null | undefined, kwargs: string | null | undefined) => {
  if (!formatStringNumber(String(template))) {
    template = formatNumber(String(template))
  }

  if (template) {
    return template
  }

  return JSON.parse(kwargs ?? '{"description": "no description"}')?.description
}

function parseCodeBlock(codeBlock: StringValueType["codeBlock"]) {
  const result = JSON.parse(codeBlock ?? "{}")
  return {
    lng: result?.language ?? "",
    tmpl: result?.code_template_str ?? "",
  }
}

function parseParams(paramsJson: StringValueType["params"]) {
  const params: ParamTypes = JSON.parse(paramsJson ?? "{}")
  return params
}

function getWindowsOffset(windows: Window[]): string {
  /**
   * `windows` should be guaranteed to have at least one value
   * at the time this fn gets called
   *
   * Potential outputs:
   * + 2%
   * - 15%
   * +/- 3%
   */
  if (!windows.length) {
    return ""
  }

  const offset = {
    value: windows[0].offset.positive,
    max: "",
    min: "",
  }
  windows.forEach((w) => {
    const match = w.parameter_name.match(/_([a-zA-Z]+$)/) ?? []
    switch (match[1]) {
      case "max":
        offset.max = "+"
        break
      case "min":
        offset.min = "-"
        break
    }
  })
  const boundedOffset = offset.max && offset.min
  return `${offset.max}${boundedOffset ? "/" : ""}${offset.min}${boundedOffset ? " " : ""}${offset.value * 100}%`
}

function getWindowsConstraintFn(constraint_fn: string): string {
  switch (constraint_fn) {
    case "mean":
      return "average"
    default:
      return constraint_fn
  }
}

function parseWindowedParams(params?: ParamTypes, stringParams?: StringValueType["params"]) {
  const column = JSON.parse(stringParams ?? "{}")?.column
  const windows: Window[] | undefined = params?.windows
  if (!windows || !windows.length) {
    return null
  }
  const windowedParams: ParamTypes = {
    column,
    range: { value: `${windows[0]?.range ?? ""}` },
    constraint_fn: { value: getWindowsConstraintFn(windows[0]?.constraint_fn) },
    offset: { value: getWindowsOffset(windows) },
  }
  return windowedParams
}

function replaceTemplateParams(template: string, params: ParamTypes) {
  return template.replace(
    /**
     * \$ - matches $ literally
     * (\w+) - matches one or more word characters (alphanumeric or underscore)
     * g - global flag to match all occurrences
     */
    /\$(\w+)/g,
    // for each matched group, replace with corresponding param if present
    (match, key: string) => {
      let param = params[key]?.value
      if (Array.isArray(param)) {
        param = param.join(", ")
      }
      return param ?? match // If no matching param, leave as is
    },
  )
}

type RenderCodeBlockProps = {
  template: StringValueType["template"]
  codeBlock: StringValueType["codeBlock"]
  params: ParamTypes
}
function RenderCodeBlock({ codeBlock, params, template }: RenderCodeBlockProps) {
  const [open, setOpen] = useState(true)

  // only run json.parse once per codeblock string if possible
  const { language, value } = useMemo(() => {
    const { lng, tmpl } = parseCodeBlock(codeBlock)

    const result = replaceTemplateParams(tmpl, params)

    return {
      language: lng,
      value: result,
    }
  }, [codeBlock, params])

  if (!language && !value) return null

  return (
    <>
      <CodeBlockContainer
        title={`${formatLaguage(language)} Code`}
        collapsible
        background="colorFillDark"
        minWidth="100%"
        onToggle={() => setOpen((c) => !c)}
        expanded={open}
      >
        {open && (
          <CodeSnippetEditor
            name={template?.replace(/\s/g, "-")}
            language={language}
            value={value ?? ""}
            readOnly={true}
            minLines={1}
            maxLines={20}
            fontSize={14}
            showLineNumbers={false}
            showGutter={false}
          />
        )}
      </CodeBlockContainer>
    </>
  )
}

interface TooltipWrapperProps {
  children: ReactNode
  title?: string
}

const TooltipWrapper = ({ children, title }: TooltipWrapperProps) => {
  // The <div> is necessary to ensure the surrounding element supports
  // mouseEnter and mouseLeave events.
  const element = title ? (
    <Tooltip title={title}>
      <div style={{ display: "inline-block" }}>{children}</div>
    </Tooltip>
  ) : (
    <>{children}</>
  )
  return element
}

const curryDynamicTagRenderer = (
  evrConfig?: { danger?: boolean },
  isExpectationDeleted?: boolean,
  isDeletionAction?: boolean,
) =>
  function RenderDynamicTextTag(
    value: string | number | string[],
    id?: string,
    history?: ParamHistory,
    tooltip?: string,
  ) {
    let newValue = value
    if (typeof value === "number") {
      newValue = formatNumber(value)
    }
    if (evrConfig?.danger !== undefined) {
      return (
        <TooltipWrapper title={tooltip}>
          <Tag key={id} $danger={evrConfig.danger}>
            {newValue}
          </Tag>
        </TooltipWrapper>
      )
    }
    if (history || isExpectationDeleted || isDeletionAction) {
      return (
        <TooltipWrapper title={tooltip}>
          <UpdatedParam
            key={id}
            $isParamDeleted={history?.removed}
            $isParamAdded={history?.added}
            $isOnDarkBackground={isExpectationDeleted}
            $isDeletionAction={isDeletionAction}
          >
            {newValue}
          </UpdatedParam>
        </TooltipWrapper>
      )
    }
    return (
      <TooltipWrapper title={tooltip}>
        <Param key={id}>{newValue}</Param>
      </TooltipWrapper>
    )
  }

type RenderStringProps = {
  template: string | null | undefined
  params: ParamTypes
  codeBlock: StringValueType["codeBlock"]
  isExpectationDeleted?: boolean
  isDeletionAction?: boolean
  evrConfig?: { danger?: boolean }
  isPreview?: boolean
  windowTooltips?: WindowTooltips
}
function RenderString({
  template,
  params,
  codeBlock,
  isExpectationDeleted,
  isDeletionAction,
  evrConfig,
  isPreview,
  windowTooltips,
}: RenderStringProps) {
  const [truncate, setTruncate] = useState<boolean>(
    Object.keys(params).length > 10 || (params.column_list?.value?.length ?? 0) > 7,
  )

  if (!template) return null

  return (
    <>
      {addDynamicStyledText({
        template: template,
        params,
        getDynamicStyleTag: curryDynamicTagRenderer(evrConfig, isExpectationDeleted, isDeletionAction),
        truncate,
        isExpectationDeleted,
        isDeletionAction,
        windowTooltips,
      })}

      {truncate && (
        <SeeAllButton size="small" type="text" onClick={() => setTruncate(false)}>
          See all
        </SeeAllButton>
      )}

      {codeBlock && !isPreview && <RenderCodeBlock codeBlock={codeBlock} params={params} template={template} />}
    </>
  )
}

type StringRenderComponentProps = {
  value: StringValueType
  evrConfig?: { danger?: boolean }
  isExpectationDeleted?: boolean
  isDeletionAction?: boolean
  kwargs?: string | null
  isPreview?: boolean
  expectationType?: string
  isValidationResult?: boolean
}

function StringRenderComponent({
  value,
  evrConfig,
  isExpectationDeleted,
  isDeletionAction,
  kwargs,
  isPreview,
  expectationType,
  isValidationResult,
}: StringRenderComponentProps) {
  const windowedParamsEnabled = useIsFeatureEnabled("windowedParamsEnabled")
  const { params: stringParams, codeBlock } = value
  if (typeof stringParams !== "string") {
    throw new Error("type error: unable to parse stringParams. expected string, got " + typeof stringParams)
  }

  const parsedKwargs = useMemo(() => JSON.parse(kwargs ?? "false"), [kwargs])

  // don't run json.parse on every render cycle if we still have the same string
  const params = useMemo(() => {
    let params
    if (windowedParamsEnabled && !isValidationResult) {
      params = parseWindowedParams(parsedKwargs, stringParams)
    }
    return params ?? parseParams(stringParams)
  }, [isValidationResult, parsedKwargs, stringParams, windowedParamsEnabled])

  const template = useMemo(() => {
    if (windowedParamsEnabled && expectationType && !isValidationResult) {
      return windowedExpectationTemplates[expectationType as WindowedExpectation](
        params?.offset?.value?.includes("+/-") ? "between" : "within",
      )
    }
    return parseTemplate(value.template, kwargs)
  }, [windowedParamsEnabled, expectationType, isValidationResult, value, kwargs, params])

  const windowTooltips = useMemo(() => {
    // At this point we're only concerned with min/max expectations for windowed params.
    // This function will likely need updating once we incorporate additional expectation types.
    if (parsedKwargs && "windows" in parsedKwargs) {
      return parsedKwargs.windows.reduce((acc: WindowTooltips, window: Window) => {
        if (window.parameter_name.match(/_min$/)) {
          acc.min_value = `-${window.offset.negative * 100}% of the ${getWindowsConstraintFn(window.constraint_fn)} of the last ${window.range} minimum values`
        }
        if (window.parameter_name.match(/_max$/)) {
          acc.max_value = `+${window.offset.positive * 100}% of the ${getWindowsConstraintFn(window.constraint_fn)} of the last ${window.range} maximum values`
        }
        return { ...acc }
      }, {})
    }

    return undefined
  }, [parsedKwargs])

  const Wrapper = !isEmpty(JSON.parse(codeBlock ?? "{}")) ? StyledExpectationWithCodeBlock : StyledExpectation

  return (
    <Wrapper aria-label="Expectation summary" $isDeletionAction={isDeletionAction}>
      <RenderString
        template={template}
        params={params}
        codeBlock={codeBlock}
        evrConfig={evrConfig}
        isExpectationDeleted={isExpectationDeleted}
        isDeletionAction={isDeletionAction}
        isPreview={isPreview}
        windowTooltips={windowTooltips}
      />
    </Wrapper>
  )
}

export { StringRenderComponent }
