/* eslint-disable react-refresh/only-export-components */ // FIXME
import { useEffect, useMemo } from "react"
import { CodeSnippetEditor, CodeSnippetEditorProps } from "src/ui/CodeSnippetEditor/CodeSnippetEditor"
import { ExpectationRenderer } from "src/Expectation/ExpectationRenderer"
import { ExpectationInformation } from "src/Expectation/ExpectationInformation"
import { ExpectationConfigForm } from "src/Expectation/uiForms/ExpectationConfigForm"
import {
  getJsonSchemaBySnakeCaseName,
  getJsonTemplateFromJsonSchema,
  removeAdvancedKwargs,
} from "src/Expectation/utils"
import stringify from "json-stable-stringify"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { DataAssetWithLatestMetricsRunQuery } from "src/api/graphql/graphql"
import { ExpectationMetaInfo } from "src/schemas/expectation-metadata-utils"
import { theme } from "src/ui/themes/theme"
import { useGetSplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"
import { BatchDefinitionDescription } from "src/DataAssets/AssetDetails/Splitters/BatchDefinitionDescription"

export type ExpectationEditorProps = {
  value: string
  onChange: (newValue: string, error?: string) => void
  metaInfo: ExpectationMetaInfo
  codeSnippetEditorProps?: Partial<CodeSnippetEditorProps>
  hideExpectationType?: boolean
  dataAssetWithLatestMetricRun?: DataAssetWithLatestMetricsRunQuery["dataAsset"]
  renderer?: ExpectationRenderer
}

export const ExpectationEditor = ({
  value,
  codeSnippetEditorProps = {},
  onChange,
  metaInfo,
  hideExpectationType,
  dataAssetWithLatestMetricRun,
  renderer,
}: ExpectationEditorProps): JSX.Element => {
  /*
   * Even though the object version probably makes more sense in general, we use the string
   * version as the source of truth because it's easier to keep in sync with the json editor
   * and we pass around the json string when talking to the backend.
   *
   * NOTE: it is the responsibility of consumers of this component to call `formatJsonString`
   * before passing it in if they want things to look nice.
   */

  const jsonSchema = useMemo(
    () => (metaInfo?.type ? getJsonSchemaBySnakeCaseName(metaInfo.type) : undefined),
    [metaInfo?.type],
  )

  const jsonTemplate = getJsonTemplateFromJsonSchema(jsonSchema)

  const allowRichUI = !!jsonSchema

  /**
   * Set Initial Editor Value when value is falsy on Mount
   */
  useEffect(() => {
    if (!value && allowRichUI && jsonTemplate) {
      onChange(jsonTemplate)
    }
  }, [allowRichUI, jsonTemplate, onChange, value])

  const batchDefinitionEnabled = useIsFeatureEnabled("batchDefinitionEnabled")

  const { splitter } = useGetSplitterData({ isVisible: true })

  return (
    <div>
      {batchDefinitionEnabled && splitter && (
        <BatchDefinitionDescription style={{ paddingBottom: theme.spacing.vertical.l }} />
      )}
      <ExpectationInformation renderer={renderer} metaInfo={metaInfo} />

      {!jsonSchema ? (
        <CodeSnippetEditor
          readOnly={false}
          fontSize={14}
          showLineNumbers={false}
          height="350px"
          language="json"
          value={value || jsonTemplate}
          onChange={(newJsonString) => {
            let errorMsg = ""
            try {
              JSON.parse(newJsonString)
            } catch (e) {
              if (e instanceof SyntaxError) {
                errorMsg = e.message
              }
            }
            onChange(newJsonString, errorMsg)
          }}
          {...codeSnippetEditorProps}
        />
      ) : (
        <ExpectationConfigForm
          key={metaInfo.type}
          value={jsonStringToFormObject(value)}
          jsonSchema={jsonSchema}
          onChange={({ data }) => {
            if (data) {
              if (hideExpectationType) {
                // prevent this from getting added to the json if we don't want it
                delete data.expectation_type
              }
              const newJsonString = formObjectToJsonString(removeAdvancedKwargs(data))
              onChange(newJsonString)
            }
          }}
          config={{ dataAssetWithLatestMetricRun: dataAssetWithLatestMetricRun }}
        />
      )}
    </div>
  )
}

export function jsonStringToFormObject(jsonString: string): Record<string, unknown> | undefined {
  try {
    return JSON.parse(jsonString)
  } catch (e) {
    return undefined
  }
}

export function formObjectToJsonString(formObject: Record<string, unknown>): string {
  return stringify(formObject, { space: 4 })
}

export function formatJsonString(value: string): string {
  return stringify(JSON.parse(value), { space: 4 })
}
