/* eslint-disable react-refresh/only-export-components */ // FIXME
import { message, Space, Alert, Form } from "antd"
import { ExpectationEditor, formObjectToJsonString } from "src/Expectation/ExpectationEditor"
import { useMutation, useQuery } from "@apollo/client"
import { useState } from "react"
import {
  EDITOR_DRAWER_TITLE,
  EDITOR_SUCCESS_MESSAGE,
  EXPECTATION_ERROR_TEXT,
  UPDATE_EXPECTATION_ERROR,
} from "src/Expectation/words"
import { ExpectationRenderer } from "src/Expectation/ExpectationRenderer"
import { UpdateExpectationDocument } from "src/api/graphql/graphql-operations"
import { Drawer } from "src/ui/Drawer/Drawer"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { merge } from "lodash-es"
import { useParams } from "react-router-dom"
import { graphql } from "src/api/graphql"
import { InteractiveExpectationFragment } from "src/api/graphql/graphql"
import { getExpectationMetaInfo } from "src/schemas/expectation-metadata-utils"
import { NonNullableObject } from "src/common/utils/nonnullable-object"
import { handleWindowedPayload } from "src/Expectation/CreateExpectationDrawer/windowedExpectationUtils"

type JSONString = string

interface EditExpectationDrawerProps {
  expectationSuiteId: string
  expectation: Omit<InteractiveExpectationFragment, "expectationId"> &
    NonNullableObject<Required<Pick<InteractiveExpectationFragment, "expectationId">>>
  expectationJson: JSONString
  renderer: ExpectationRenderer
  isVisible: boolean
  onClose: () => void
}

interface FooterProps {
  onSave: () => void
  saveDisabled: boolean
}

function EditExpectationFooter({ onSave, saveDisabled }: FooterProps) {
  return (
    <Drawer.Footer>
      <Drawer.FooterButton type="primary" onClick={onSave} disabled={saveDisabled}>
        Save
      </Drawer.FooterButton>
    </Drawer.Footer>
  )
}

function formatValue(value: string, mode: "yaml" | "python" | "json"): string {
  try {
    if (mode === "json") {
      return JSON.stringify(JSON.parse(value), null, 4)
    }
  } catch {
    return value
  }
  return value
}

export function getErrorType(value: string): keyof typeof EXPECTATION_ERROR_TEXT {
  if (!value) {
    return "EMPTY"
  }

  let obj = {}
  try {
    obj = JSON.parse(value)
  } catch {
    return "INVALID_JSON"
  }

  if (!("kwargs" in obj)) {
    return "MISSING_KWARGS"
  }

  for (const key of Object.keys(obj)) {
    if (!["kwargs", "meta"].includes(key)) {
      return "UNEXPECTED_FIELD"
    }
  }

  return "UNEXPECTED_ERROR"
}

function getInitialValue(json: string): string {
  let parsed = JSON.parse(json)
  if (parsed.kwargs) {
    parsed = merge(parsed, parsed.kwargs)
    delete parsed.kwargs
  }
  if (parsed.meta) {
    parsed = merge(parsed, { notes: parsed.meta.notes })
    delete parsed.meta
  }
  return formatValue(JSON.stringify(parsed), "json")
}

const DataAssetWithLatestMetricsRunDocument = graphql(`
  query dataAssetWithLatestMetricsRun($id: UUID!) {
    dataAsset(id: $id) {
      id
      __typename
      latestMetricRun {
        dataAssetId
        metrics {
          columnDataType
          columnName
          mean
          median
          nullCount
          valueRangeMax
          valueRangeMin
          valueRangeMaxUnion {
            __typename
            ...MetricValueStringTypeFragment
            ...MetricValueFloatTypeFragment
          }
          valueRangeMinUnion {
            __typename
            ...MetricValueStringTypeFragment
            ...MetricValueFloatTypeFragment
          }
        }
        rowCount
      }
    }
  }
`)

export function EditExpectationDrawer({
  expectationSuiteId,
  expectation,
  expectationJson,
  renderer,
  isVisible,
  onClose,
}: EditExpectationDrawerProps) {
  const [value, setValue] = useState(getInitialValue(expectationJson))
  const [expectationPayload, setExpectationPayload] = useState<string>("")
  const [errorText, setErrorText] = useState<string | undefined>("")

  const { assetId = "" } = useParams<{
    assetId: string
  }>()
  const { data: dataAssetQueryResult } = useQuery(DataAssetWithLatestMetricsRunDocument, {
    variables: {
      id: assetId,
    },
    skip: !assetId,
  })

  const [updateExpectation, updateExpectationMutation] = useMutation(UpdateExpectationDocument, {
    variables: {
      input: {
        config: expectationPayload,
        expectationSuiteId,
        id: expectation?.expectationId,
      },
    },
    refetchQueries: ["expectationSuite"],
    onCompleted: () => {
      message.success(EDITOR_SUCCESS_MESSAGE, MESSAGE_DURATION_SECONDS)
      onClose()
    },
  })
  const handleCancel = () => {
    if (updateExpectationMutation.error?.message) {
      updateExpectationMutation.reset()
    }
    onClose()

    // AntD@4.22.3 destroyOnClose seems broken
    setErrorText("")
    setValue(getInitialValue(expectationJson))
  }

  const handleSave = () => {
    updateExpectation()
  }
  const metaInfo = getExpectationMetaInfo({ expectation })

  return (
    <Drawer
      title={EDITOR_DRAWER_TITLE}
      onClose={handleCancel}
      open={isVisible}
      onClick={(e) => e.stopPropagation()}
      footer={<EditExpectationFooter onSave={handleSave} saveDisabled={!!errorText} />}
    >
      <Space direction="vertical" size="large">
        <Form layout="vertical">
          {metaInfo && (
            <ExpectationEditor
              value={value}
              onChange={(newVal, error?) => {
                setErrorText(error)
                setValue(newVal)
                const parsedExpectationPayload = handleWindowedPayload(JSON.parse(newVal), metaInfo.type)
                const expectationPayloadString = formObjectToJsonString(parsedExpectationPayload)
                setExpectationPayload(expectationPayloadString)
              }}
              metaInfo={metaInfo}
              hideExpectationType={true}
              dataAssetWithLatestMetricRun={dataAssetQueryResult?.dataAsset}
              renderer={renderer}
            />
          )}
        </Form>
        {errorText && (
          <div>
            <Alert type="error" message={errorText} />
          </div>
        )}
        {updateExpectationMutation.error && (
          <AlertBanner message={UPDATE_EXPECTATION_ERROR} description={updateExpectationMutation.error?.message} />
        )}
      </Space>
    </Drawer>
  )
}
